@import "src/styles";

.switch {

    &__label {
        margin-block-end: .5rem;
    }

    &__option-wrapper {
        display: flex;
        position: relative;
        border: 2px solid rgba(var(--c-black-rgb), .3);
        border-radius: 2rem;
        padding: .5rem;

        @include is-dark-child {
            border-color: rgba(var(--c-white-rgb), .3);

            &:focus-within {
                border-color: rgba(var(--c-white-rgb), 1);
            }
        }

        &:focus-within {
            border-color: rgba(var(--c-black-rgb), 1);
        }

        &--has-error {
            border-block-end-color: var(--c-negative);

            @include is-dark-child {
                border-block-end-color: var(--c-negative);
            }
        }
    }

    &__label-wrapper {
        @extend %flex-align-center;

        position: relative;
        z-index: 20;
        width: 100%;
        cursor: pointer;
        user-select: none;
    }

    &__input {
        position: absolute;
        opacity: 0;
        z-index: -10;
    }

    &__option-label {
        @extend %input-label;

        padding: .5rem;
        font-size: .875rem;
        text-align: center;
        transition: color .3s ease-in-out;

        &--is-selected {
            color: var(--c-black);
        }
    }

    &__active-indicator {
        position: absolute;
        z-index: 10;
        width: calc((100% - 1rem) / 2);
        height: calc(100% - 1rem);
        border-radius: 2rem;
        overflow: clip;
        background-color: var(--c-accent);
        transition: translate .3s ease-in-out;

        &--selected-second {
            translate: 100% 0;

            @include rtl {
                translate: -100% 0;
            }
        }
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
