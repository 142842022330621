@import "src/styles";

.searchable-multi-value-input {
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);
    }

    &__label {
        margin-block-end: .5rem;
    }

    &__value-list {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        margin: 0;
        padding-block-end: .5rem;
        border-block-end: none;
        border-radius: var(--border-radius);
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        box-shadow: var(--box-shadow);
    }

    &__value-item {
        list-style-type: none;
    }
}
