@import "src/styles";

$component: people-list-section;

.#{$component} {

    &__list {
        @include three-columns-line-grid(#{$component}__list-item);

        column-gap: 0;
    }

    &__list-item {
        flex-grow: 1;
    }
}
