@import "src/styles";

.people-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    column-gap: .125rem;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(6, 1fr);
    }

    &__list-item {
        display: flex;
        align-items: flex-end;
    }
}
