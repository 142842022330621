@import "src/styles";

$component: navigation-item;

.#{$component} {
    --navigation-item-line-width: 2.5rem;

    @extend %nav-item;

    &__link {
        @extend %nav-item-button;

        &--is-active {
            color: var(--c-primary);
        }
    }

    &__link:hover,
    &__link:focus {

        &::before {
            opacity: 1;
        }

        .#{$component}__label {
            transform: translateX(var(--navigation-item-line-width));
        }
    }

    &__label {
        @extend %nav-item-label;
    }
}
