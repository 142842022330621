@import "src/styles";

.picture {
    display: flex;
    width: fit-content;
    max-width: 100%;
    overflow: clip;
    background-color: var(--c-line);

    @include is-dark-child {
        background-color: var(--c-line-dark);
    }

    &--has-scaling:hover &__image {
        scale: 1.15;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: .15s scale ease-in-out;
    }
}
