@import "src/styles";

.video-modal {

    &__poster-wrapper {
        @extend %flex-align-center;

        position: relative;
        background-color: var(--c-white);

        @include is-dark-child {
            background-color: var(--c-black);
        }
    }

    &__poster,
    &__poster-placeholder {
        aspect-ratio: 2;
    }

    &__poster-image {
        object-fit: cover;
    }

    &__poster-placeholder {
        width: 100%;
        background: var(--c-blue-dark);
    }

    &__play-button-wrapper {
        @extend %click-extend;
        @extend %flex-align-center;
    }

    &__play-button {
        @extend %flex-align-center;
        @include circle(3rem);

        padding: 0;
        transition: transform .3s ease, opacity .3s ease;
        opacity: .5;

        @include for-size(tablet-portrait-up) {
            @include circle(4rem);
        }

        &:hover,
        &:focus:focus-visible {
            opacity: 1;
            transform: scale(1.125);
        }
    }

    &__dialog-button {
        @extend %button--no-background;

        gap: .5rem;
        height: 2.375rem;
        padding-block: .5rem;
        padding-inline: .875rem 1rem;
        color: var(--c-blue-dark);
        border: 1px solid currentColor;

        &:hover,
        &:focus {
            color: var(--c-primary);
        }
    }

    &__dialog-button-icon {
        @include icon-size(.875rem);
    }

    &__dialog {
        background-color: var(--c-blue-medium);
    }

    &__dialog-content {
        height: 100%;
    }

    &__video {
        @extend %click-extend;
    }

    &__video-element {
        max-height: unset;
    }

    &__video-controls-wrapper {
        inset-block-start: unset;
        inset-block-end: 0;
    }
}
