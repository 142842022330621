@import "src/styles";

.navigation-item-list {
    width: 100%;

    &--is-sub-navigation &__item {
        --navigation-item-line-width: 1.75rem;

        font-size: 1.375rem;
    }

    &__back-button {
        margin-block-end: 1.5rem;
        padding-inline-start: .5rem;
        color: var(--c-grey-light);
        background: none;

        &:hover,
        &:focus {
            color: var(--c-white);
        }

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__back-button-icon {
        @include icon-size(1rem);

        color: var(--c-white);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        padding: 0;
        list-style-type: none;
    }
}
