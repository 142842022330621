@import "src/styles";

.key-figures {

    @at-root {

        .header-end + & {
            margin-block-start: -4rem;
            background-color: transparent;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 2rem;
        border: 1px solid var(--c-line);
        border-inline-end: 0;
        padding-block: 4rem;
        padding-inline: 2rem;
        color: var(--c-blue-dark);
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            color: var(--c-white);
            background-color: var(--c-blue);
        }

        @include for-size(phone-only) {
            display: flex;
            flex-direction: column;
        }

        @include for-size(tablet-portrait-up) {
            display: flex;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline: 4rem;
        }
    }

    &__item {
        width: 100%;
        padding-inline-end: 2rem;
        list-style-type: none;

        &:last-of-type {
            padding-inline-end: 0;
        }
    }
}
