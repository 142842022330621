@import "../helpers";

%input {
    width: 100%;
    margin: 0;
    padding-block: .5rem;
    border: none;
    border-block-end: 2px solid rgba(var(--c-black-rgb), .3);
    border-radius: var(--border-radius);
    font: inherit;
    color: currentColor;
    font-weight: var(--fw-bold);
    background-color: transparent;

    @include is-dark-child {
        border-block-end-color: rgba(var(--c-white-rgb), .3);

        &:focus {
            border-block-end-color: rgba(var(--c-white-rgb), 1);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:focus {
        outline: none;
        border-block-end-color: rgba(var(--c-black-rgb), 1);
    }
}
