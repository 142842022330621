@import "src/styles";

.contact-person-card {
    display: flex;
    flex-direction: column;

    &__portrait {
        flex-grow: 1;
    }

    &__portrait-image {
        object-fit: cover;
    }

    &__meta-wrapper {
        @extend %card;
    }

    &__job-title {
        font-size: 1.125rem;
        color: var(--c-primary);
    }

    &__name {
        @extend %heading-4;

        margin-block-start: .5rem;
        line-height: 1.25;
        color: var(--c-white);
    }

    &__email {
        @extend %text-ellipsis;

        width: 100%;
        margin-block-start: 3rem;
        padding-block-end: 0;
        padding-inline: 0;
        word-break: break-word;
    }
}
