.select-input {
    display: block;
    position: relative;
    z-index: 10;

    &__label {
        margin-block-end: .5rem;
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
