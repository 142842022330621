@import "src/styles";

$component: "type-header";

.#{$component} {
    --header-block-start-padding: 12.5rem;
    --header-block-end-padding: 6.25rem;
    --header-image-offset: 3rem;

    position: relative;
    color: var(--c-black);
    background-color: var(--c-white);

    @include is-dark-parent {
        color: var(--c-white);
        background-color: var(--c-blue-dark);
    }

    @include for-size(tablet-portrait-up) {
        --header-block-start-padding: 16rem;
        --header-block-end-padding: 8rem;
    }

    &--has-full-title-width &__content {
        min-width: 100%;
    }

    &--has-image.#{$component}--has-margin-bottom {
        --header-content-start: calc(var(--header-height) - var(--header-block-start-padding));
        --header-block-end: calc(var(--header-content-start) + 3rem);

        @include for-size(tablet-portrait-up) {
            margin-block-end: calc(var(--header-picture-height) - var(--header-block-end));
        }
    }

    &--has-image.#{$component}--has-dark-neighbour.#{$component}--has-margin-bottom {

        @include for-size(tablet-portrait-up) {
            margin-block-end: 0;
        }

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {

                &::after {
                    content: "";
                    display: block;
                    height: calc(var(--header-picture-height) - var(--header-block-end));
                }
            }
        }

        .#{$component}__picture {
            height: calc(var(--header-picture-height) - var(--header-protrusion-height));
        }
    }

    &__wrapper {
        padding-block: var(--header-block-start-padding) var(--header-block-end-padding);

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: flex-start;
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        z-index: 30;
        width: 100%;
    }

    &__breadcrumbs {
        order: -2;
        margin-block-end: 2rem;
    }

    &__picture-wrapper {
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        z-index: 20;
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-start: var(--header-block-start-padding);
            inset-inline-end: var(--wrapper-padding);
            width: calc((100% - (var(--wrapper-padding) * 2)) / 1.5);
            margin-block-start: 0;
        }

        @include for-size(laptop-up) {
            inset-inline-end: 0;
            width: calc(100% / 1.5);
        }
    }

    &__picture {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-start: calc(var(--header-image-offset) * -1);
            inset-inline-end: 0;
            height: var(--header-picture-height);
            aspect-ratio: 1.5;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__title {

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }

        h1, h2, h3, h4, h5 {
            line-height: 1;
        }

        small {
            font-size: 2rem;
        }

        &:not(:last-child) {
            margin-block-end: 1rem;
        }
    }

    &__caption {
        @extend %label;

        order: -1;
        margin-block-end: 1rem;
        color: var(--c-primary);
    }

    &__location,
    &__date {
        @extend %sub-text;

        color: var(--c-grey-light);
    }

    &__link-button {
        margin-block-start: 1rem;
    }

    &__quick-links {
        margin-block-start: 2rem;
    }

    &__grid-lines {
        z-index: 10;
    }
}
