@import "src/styles";

$component: "article-card";

.#{$component} {
    --c-article-card: var(--c-white);
    --c-article-card-category: var(--c-primary);
    --c-article-card-background: var(--c-blue);
    --c-read-more: currentColor;

    display: flex;
    flex-direction: column;
    height: 100%;

    &--news-release {
        --c-article-card-category: var(--c-primary);
        --c-article-card-background: var(--c-blue);
    }

    &--press-release {
        --c-article-card-category: var(--c-primary);
        --c-article-card-background: var(--c-blue-medium);
    }

    &--share-buyback {
        --c-article-card-category: var(--c-blue-dark);
        --c-article-card-background: var(--c-grey);
    }

    &--hide-meta {

        .#{$component}__header {
            min-height: unset;
        }

        .#{$component}__meta-wrapper {
            display: none;
        }

        .#{$component}__text-wrapper {
            padding-block-start: 2rem;
        }
    }

    &__header {
        min-height: 3rem;
    }

    &__image-placeholder,
    &__picture {
        width: 100%;
        aspect-ratio: 16 / 11;
    }

    &__image-placeholder {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }

    &__picture + &__image-placeholder {

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--c-article-card);
        background-color: var(--c-article-card-background);

        &:hover,
        &:focus-within {
            --c-read-more: var(--c-accent);
        }
    }

    &__meta-wrapper {
        position: relative;
        width: fit-content;
        padding-block: 1rem;
        padding-inline: 2rem;
        background-color: inherit;
        translate: 0 -50%;
    }

    &__category {
        color: var(--c-article-card-category);
    }

    &__date {
        line-height: 2;
    }

    &__text-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        padding-block-start: 0;
    }

    &__title {
        font-size: 1.375rem;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__read-more-label {
        @extend %underscore-button;

        margin-block-start: 3rem;
        font-size: 1rem;
        color: var(--c-read-more);
    }
}
