@import "src/styles";

.presentation {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__presentation {
        position: relative;

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 1.5);
        }
    }

    &__pdf-reader-wrapper {

        @include for-size(tablet-portrait-up) {
            padding: 2rem;
        }
    }

    &__pdf-reader {
        border: 1px solid var(--c-line);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background-color: var(--c-blue);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            width: calc(100% / 3);
            padding: 2rem;
        }
    }

    &__caption {
        @extend %heading-3;

        order: -1;
    }

    &__title {
        margin-block-start: 1rem;
    }

    &__download-button-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 4rem;
        }
    }

    &__download-button {
        width: fit-content;
    }
}
