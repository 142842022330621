@import "src/styles";

$component: "figure";

.#{$component} {
    margin: 0;
    overflow: clip;

    &--is-full-width {

        .#{$component}__wrapper {
            width: 100%;
            margin-inline: 0;
            padding-inline: 0;
        }

        .#{$component}__caption-wrapper {
            width: var(--wrapper-width);
            margin-inline: auto;
            padding-inline: var(--wrapper-padding);
        }
    }

    &__picture {
        width: 100%;
    }

    &__image {
        width: 100%;
        max-height: 90vh;
        object-fit: cover;
    }

    &__caption-wrapper {
        width: 100%;
        margin-block-start: 1rem;
        margin-inline: 0;
        padding-inline: 0;
    }

    &__caption {
        @extend %label;
    }
}
