@import "src/styles";

$component: chevron-navigation;

.#{$component} {
    display: flex;
    gap: 2rem;

    @include is-dark-child {

        .#{$component}__button {
            border-color: var(--c-white);
            color: var(--c-white);

            &:hover,
            &:focus {
                color: var(--c-blue-dark);
                background-color: var(--c-white);
            }

            &:disabled {
                color: var(--c-white);
                background-color: transparent;
            }
        }
    }

    &__button {
        @include circle(2.5rem);

        border: 1px solid rgba(var(--c-blue-rgb), .5);
        padding: 0;
        color: var(--c-blue);
        background: none;

        &:hover,
        &:focus {
            color: var(--c-white);
            background-color: var(--c-blue);
        }

        &:disabled {
            opacity: .5;
            color: var(--c-blue);
            background-color: transparent;
        }

        @include is-dark-child {
            color: var(--c-white);
            border-color: var(--c-white);

            &:hover,
            &:focus {
                color: var(--c-blue-dark);
                background-color: var(--c-white);
            }
        }
    }

    &__icon {
        @include icon-size(.75rem);
    }
}
