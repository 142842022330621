@import "src/styles";

.shareholders {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {

        @include for-size(laptop-up) {
            width: calc(100% / 1.5);
        }
    }

    &__table {
        width: 100%;
    }
}
