@import "src/styles";

.block-quote {
    --c-block-quote-theme-background: var(--c-white);

    color: var(--c-black);

    @include is-dark-child {
        --c-block-quote-theme-background: var(--c-blue-dark);

        color: var(--c-white);
    }

    &__text-wrapper {
        width: 100%;
        padding: 2rem;
        border: 1px solid var(--c-primary);
    }

    &__text {
        max-width: 42rem;
        font-size: 1.75rem;
        font-style: italic;
        line-height: 1.5;
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        inset-block-start: -1px;
        column-gap: .5rem;
        padding-block: 1rem;
        padding-inline: 5.5rem;

        @include for-size(tablet-portrait-up) {
            padding-inline: 8.5rem 0;
        }

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            inset-inline-start: 2rem;
            z-index: 10;

            @include for-size(tablet-portrait-up) {
                inset-inline-start: 5rem;
            }
        }

        &::before {
            inset-block-start: -1px;
            width: 3.5rem;
            height: .25rem;
            background-color: var(--c-block-quote-theme-background);
        }

        &::after {
            inset-block-start: 0;
            width: 3rem;
            height: 1px;
            background-color: var(--c-primary);
            transform-origin: 0 0;
            rotate: 45deg;
        }
    }

    &__author {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__jobtitle {
        font-size: .8125rem;
        line-height: 2;
    }
}
