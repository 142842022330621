@import "src/styles";

.table {
    display: grid;
    position: relative;
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--c-line);
    border-inline-end: 0;
    padding: 0;
    text-align: start;
    overflow-x: auto;
    overflow-y: hidden;
    border-spacing: 0;
    border-radius: var(--border-radius);
    background-color: var(--c-white);

    @include is-dark-child {
        border-color: var(--c-line-dark);
        background-color: var(--c-blue-dark);
    }

    @include for-size(tablet-portrait-up) {
        width: fit-content;
    }
}
