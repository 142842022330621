@import "src/styles";

.contact-form {
    display: flex;

    &__form {
        flex-grow: 1;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__title {
        @extend %heading-3;
    }

    &__input {
        margin-block-start: 2rem;
    }

    &__submit-button {
        @extend %underscore-button;

        justify-self: flex-end;
        margin-block-start: 3rem;
    }

    &__recaptcha-disclaimer,
    &__recaptcha-error {
        margin-block-start: 1rem;
    }
}
