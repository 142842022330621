@import "src/styles";

.impact-card {
    padding-block-end: 3rem;

    @include for-size(tablet-portrait-up) {
        padding-block-end: 2rem;
    }

    &__title {
        @extend %label;

        color: var(--c-accent);
    }

    &__paragraph-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-block-start: 4rem;
        font-size: 1.375rem;
        line-height: 1.45;

        @include for-size(tablet-portrait-up) {
            min-height: calc(5em * 1.45);
            margin-block-start: 8rem;
        }
    }

    &__paragraph {
        font-size: inherit;
        line-height: inherit;
    }
}
