@import "src/styles";

.article-search-results {

    &__amount-title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__title {
        @extend %heading-2;

        margin-block-start: 1rem;
    }

    &__list {
        margin-block-start: 2rem;
    }

    &__list-item {
        border: 1px solid var(--c-line);
        border-inline-end: 0;
        background-color: var(--c-white);

        &:not(:last-child) {
            border-block-end: none;
        }
    }
}
