@import "src/styles";

$component: "highlighted-article";

.#{$component} {
    --c-read-more: currentColor;

    position: relative;
    z-index: 10;

    @include is-dark-parent {
        color: var(--c-white);
    }

    @at-root {

        .header-end + & &__wrapper {
            padding-block-start: var(--section-wrapper-padding);
        }
    }

    &--show-image {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                display: grid;
                grid-template-columns: 50% 50%;
            }
        }

        .#{$component}__picture {
            display: block;
            position: relative;
            width: 100%;
            background-color: transparent;
        }

        .#{$component}__image {
            position: relative;
            aspect-ratio: 1.75;
            margin-block-start: -4rem;
            max-height: 75vh;
            object-fit: cover;

            @include for-size(tablet-portrait-up) {
                margin-block-start: -6rem;
            }

            @include for-size(tablet-landscape-up) {
                height: calc(100% + 6rem);
                max-height: unset;
                aspect-ratio: unset;
            }
        }

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                padding-inline-start: 2rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-inline-start: 4rem;
            }
        }
    }

    &__picture {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-block-start: 2rem;
    }

    &__title {
        @extend %heading-3;

        margin-block-start: 1rem;
    }

    &__article-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-block-start: 3rem;

        &:hover,
        &:focus-within {
            --c-read-more: var(--c-accent);
        }

        @include for-size(tablet-landscape-up) {
            margin-block-start: 4rem;
        }
    }

    &__article-title {
        @extend %heading-2;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__article-category {
        @extend %label;

        order: -1;
        color: var(--c-primary);
    }

    &__read-more-label {
        @extend %underscore-button;

        margin-block-start: 3rem;
        font-size: 1rem;
        color: var(--c-read-more);
    }
}
