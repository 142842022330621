@import "src/styles";

.tabs-list {
    width: 100%;
    overflow-x: auto;

    &__list {
        display: flex;
        gap: 0;
        width: fit-content;
        margin: 0;
        margin-inline: auto;

        @include for-size(tablet-portrait-up) {
            gap: 2rem;
        }
    }
}
