@import "src/styles";

.page-section-renderer {

    &__header {
        position: relative;
        z-index: 20;
    }

    &__section {
        position: relative;
        z-index: 20;
        background-color: var(--c-white);

        @include is-dark-child {
            background: var(--c-blue-dark);
        }

        @include is-dark-parent {
            background: var(--c-blue-dark);
        }

        &--is-background {
            z-index: 10;
        }

        &--is-foreground {
            z-index: 30;
        }

        &--is-article-overview {
            z-index: 40;
        }
    }
}
