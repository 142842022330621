@import "src/styles";

.solutions-carousel {
    width: 100%;
    overflow: hidden;

    @include is-dark-parent {
        color: var(--c-white);
    }

    &--is-animation-disabled &__list {
        transition: none;
    }

    &__title {
        @extend %heading-2;
    }

    &__no-results-wrapper,
    &__list {
        margin-block-start: 2rem;
    }

    &__no-results {

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__list {
        display: flex;
        align-items: stretch;
        gap: 1rem;
        width: calc(100% + 4rem);
        margin-inline-start: -2rem;
        padding-inline: 50%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            gap: unset;
            width: 100%;
            height: fit-content;
            margin-inline-start: unset;
            padding: unset;
            overflow: unset;
            transition: transform .75s ease-out;
            transform: translateX(negative(var(--carousel-scroll-progress)));
        }
    }

    &__item {
        min-width: calc(100vw - 4rem);
        min-height: 100%;
        padding-inline-end: 2rem;
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            min-width: calc(100% / 2);
        }
    }

    &__navigation-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-start: 2rem;
    }

    &__navigation {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }
}
