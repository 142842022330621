@import "src/styles";

.quick-link {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-block: 1.5rem 1rem;
    padding-inline: 0 .5rem;

    &--is-large &__link {
        font-size: 2rem;
        font-weight: var(--fw-regular);
    }

    &__link {
        @extend %label;

        font-size: 1.125rem;

        &::before {
            display: none;
        }

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
        }
    }

    &__description {
        @extend %text-with-underline;

        justify-self: flex-end;
        position: relative;
        font-size: 1rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey);

        @include is-dark-child {
            color: var(--c-grey-light);
        }
    }
}
