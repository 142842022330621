@import "src/styles";

.financial-results {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-2;
    }

    &__year-title {
        @extend %heading-3;
    }

    &__year-title,
    &__year-selector {
        margin-block-start: 2rem;
    }

    &__table {
        margin-block-start: 1.5rem;
    }
}
