@import "src/styles";

.searchable-value-input-option {
    list-style-type: none;

    &__button {
        @extend %button--no-background;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1rem;
        text-align: start;
        text-transform: none;
        color: currentColor;
        border-radius: 0;

        &:enabled:hover,
        &:enabled:focus {
            background-color: rgba(var(--c-black-rgb), .1);

            @include is-dark-child {
                background-color: rgba(var(--c-white-rgb), .1);
            }
        }
    }

    &__label {
        font-size: 1rem;
        font-weight: var(--fw-regular);
    }

    &__query {
        font-weight: var(--fw-bold);
        text-decoration: underline;
    }

    &__secondary-label {
        @extend %input-label;

        margin-block-start: .25rem;
        color: currentColor;
    }
}
