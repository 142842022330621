@import "src/styles";

.department-details {
  background-color: var(--c-white);

  @include is-dark-child {
    background-color: var(--c-blue-dark);
  }

  &__summary {
    padding-block: 3rem;
    border-inline-end: none;
    background-color: var(--c-white);

    @include is-dark-child;
  }

  &__items-wrapper {
    color: var(--c-white);

    @include for-size(tablet-landscape-up) {
      display: flex;
    }
  }

  &__group-title {
    color: var(--c-primary);
  }

  &__vision-title {
    color: var(--c-green);
  }

  &__connectivity-title {
    color: var(--c-blue-sky);
  }

  &__manufacturing-title {
    color: var(--c-blue-light);
  }
}
