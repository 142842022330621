@import "src/styles";

.buyback-card {
    @extend %card;

    display: flex;
    flex-direction: column;
    padding-block-start: 4rem;

    &__date {
        @extend %sub-text;
    }

    &__title {
        @extend %heading-4;

        margin-block: 1rem;
        color: var(--c-white);
    }

    &__link-button {
        justify-self: flex-end;
        margin-block-start: 3rem;
    }
}
