@keyframes spin {
    to { rotate: 1turn; }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes pulse {
    0% { opacity: 0; }
    75% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes fade-out-splash {
    0% { opacity: 1; }
    55% { opacity: 1; }
    85% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes scale-in {
    from { scale: 0; }
    to { scale: 1; }
}

@keyframes scale-in-large {
    from { scale: 0; }
    to { scale: 1.5; }
}

@keyframes skeleton-load {
    from { translate: -100% 0; }
    to { translate: 100% 0; }
}

@keyframes scroll-down-indicator-animation {
    0% { transform: scaleY(0) translateY(0) }
    50% { transform: scaleY(100%) translateY(0); }
    100% { transform: scaleY(100%) translateY(100%); }
}
