.textarea {
    display: block;

    &__label {
        margin-block-end: .5rem;
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
