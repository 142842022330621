@import "src/styles";

$component: "visual-header-carousel";

.#{$component} {

    &__picture {
        @extend %click-extend;

        z-index: var(--visual-header-image-index);
        transition: clip-path 2.5s var(--strong-out-ease);
        clip-path: inset(0 100% 0 0);

        &--should-show {
            clip-path: inset(0 0 0 0);

            .#{$component}__image {
                scale: 1.05;
            }
        }

        &--no-animation {
            transition-duration: 0s;
        }
    }

    &__image {
        object-fit: cover;
        transition: scale ease-out;
        transition-duration: inherit;
    }
}
