@import "src/styles";

.links-card {
    padding-block-start: 2.5rem;

    &__title {
        @extend %heading-4;

        color: var(--c-white);
    }

    &__link-wrapper {
        margin-block-start: 1.5rem;
    }

    &__link {
        @extend %underscore-button;

        & + & {
            margin-block-start: .5rem;
        }
    }
}
