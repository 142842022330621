@import "src/styles";

.tooltip {

    &__tag {
        text-transform: capitalize;
    }

    &__address {
        display: flex;
        gap: .5rem;

        p {
            line-height: 1.25;
        }

        &--icon {
            @include icon-size(1.25rem);
        }
    }
}

// Overwrite for Google Maps InfoWindow
.gm-style .gm-style-iw-c {
    max-width: 17.625rem !important;
    margin: 0;
    padding: 2rem !important;
    padding-top: 1rem !important;
    border-radius: unset;
    background: var(--c-line-dark);
    box-shadow: none;

    * {
        overflow: hidden !important;
        color: #fff;
    }

    strong {
        color: var(--c-primary);
    }

    h2 {
        margin-bottom: 1rem !important;
    }
}

.gm-style .gm-style-iw-tc::after {
    content: none;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;

    width: 2rem !important;
    height: 2rem !important;

    span {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-top: .5rem !important;
        background-color: var(--c-white) !important;
    }
}

.gm-style-iw-d > div {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
