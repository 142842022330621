@import "src/styles";

.share-buyback-link {

    &__link {
        @extend %label;

        text-decoration: none;
    }

    &__label {
        @extend %text-with-underline;

        font-size: 1rem;
        color: var(--c-grey);

        @include is-dark-child {
            color: var(--c-grey-light);
        }
    }
}
