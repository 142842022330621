@import "src/styles";

.sdg {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: 2rem;
    padding-block-end: 6rem;
    color: var(--c-white);
    background: var(--c-green);

    @include for-size(tablet-portrait-up) {
        padding: 8rem 4rem;
    }

    &__logo {
        width: 5rem;
        aspect-ratio: 1;
    }

    &__title,
    &__intro {
        max-width: 37rem;
        text-shadow: var(--title-shadow);
    }

    &__intro {
        @extend %html-format;
    }

    &__intro p + p {
        margin-block-start: 1rem;
    }

    &__title {
        font-size: 3rem;
    }
}
