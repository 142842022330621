@import "src/styles";

$component: text-image;

.#{$component} {

    &--has-link &__content {
        padding-block-end: 3rem;
    }

    &--has-card {

        &.#{$component}--image-align-right {

            .#{$component}__content {
                padding-inline-end: 2rem;
            }

            .#{$component}__picture-wrapper {

                @include for-size(tablet-portrait-up) {
                    padding-inline-start: 4rem;
                    padding-inline-end: 0;
                }
            }
        }

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: calc(2rem + var(--section-wrapper-padding));
            }
        }

        .#{$component}__content {
            padding-block: 4rem;

            @include for-size(tablet-portrait-up) {
                padding-block: 8rem;
                padding-inline-start: 2rem;
            }
        }

        .#{$component}__picture-wrapper {

            @include for-size(tablet-portrait-up) {
                padding-inline-end: 4rem;
            }
        }
    }

    &--image-align-left {

        .#{$component}__inner-wrapper {
            display: flex;
            flex-direction: column-reverse;

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }

        .#{$component}__content {
            border-block-end: 1px solid var(--c-line);
            border-inline-end: unset;

            @include for-size(tablet-portrait-up) {
                padding-inline-start: 3rem;
                padding-inline-end: 0;
            }

            @include for-size(laptop-up) {
                padding-inline-start: 4rem;
            }
        }

        .#{$component}__picture-wrapper {
            flex-direction: column;
            inset-inline-start: 0;

            @include for-size(tablet-portrait-up) {
                flex-direction: row;
            }
        }
    }

    &--image-align-right {

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                border-inline-end: unset;
            }
        }

        .#{$component}__picture-wrapper {
            inset-inline-end: 0;
        }

        .#{$component}__card-wrapper {
            inset-inline-start: 0;
        }
    }

    &--is-card-type {

        .#{$component}__inner-wrapper {
            padding-block-end: 4rem;

            @include for-size(tablet-portrait-up) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                padding-block-end: 2rem;
            }
        }

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                border-inline-end: unset;
                padding-block: 4rem;
                padding-inline-end: 4rem;
                width: 100%;
                height: 100%;
                min-height: 31.875rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block: 6rem;
                padding-inline-end: 10rem;
            }
        }

        .#{$component}__picture-wrapper {
            border-inline-start: 1px solid var(--c-line);

            @include for-size(tablet-portrait-up) {
                position: relative;
                width: 100%;
                height: 100%;
                border-block: 1px solid var(--c-line);
                border-inline: unset;
                background: var(--c-white);
            }

            @include is-dark-child {
                border-color: var(--c-line-dark);
                background: var(--c-blue-dark);
            }
        }

        .#{$component}__picture {
            width: calc(100% - 6rem);

            @include for-size(tablet-portrait-up) {
                position: relative;
                width: calc(100% - 2rem);
                max-width: 31.875rem;
                height: auto;
                min-height: 100%;
                aspect-ratio: 1;
            }

            @include for-size(tablet-landscape-up) {
                width: calc(100% - 4rem);
            }
        }

        &.#{$component}--image-align-left {

            .#{$component}__card-wrapper {
                inset-inline-end: 0;
            }

            .#{$component}__picture-wrapper {
                order: -1;
            }
        }
    }

    &--is-wide-image-type {

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 3);
            }
        }

        .#{$component}__picture-wrapper {
            width: 100%;

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 1.5);
            }
        }
    }

    &--is-narrow-image-type {

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 1.5);
            }
        }

        .#{$component}__picture-wrapper {
            width: 100%;

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 3);
            }
        }
    }

    &--is-compact {

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                border: unset;
                background: transparent;
                padding: 0;
            }
        }

        .#{$component}__picture {

            @include for-size(tablet-portrait-up) {
                width: calc(100% - 4rem);
            }
        }
    }

    &--is-card-type,
    &--has-big-title {

        .#{$component}__title {

            h1, h2, h3, h4 {
                @extend %heading-1-unique;
            }
        }
    }

    &--has-image-object-fit-contain {

        &.#{$component}--image-align-left {

            .#{$component}__content {

                @include for-size(tablet-portrait-up) {
                    padding-inline-end: 0;
                }
            }
        }

        &.#{$component}--image-align-right {

            .#{$component}__content {

                @include for-size(tablet-portrait-up) {
                    padding-inline-start: 0;
                }
            }
        }

        .#{$component}__picture {
            background-color: transparent;

            @include is-dark-child {
                background-color: transparent;
            }
        }

        .#{$component}__image {
            object-fit: contain;
        }
    }

    &__wrapper {
        display: flex;
    }

    &__inner-wrapper {
        flex-grow: 1;
        position: relative;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: center;
        }
    }

    &__content {
        height: fit-content;
        border: 1px solid var(--c-line);
        border-block-end: unset;
        padding-block: 2rem;
        padding-inline-end: 2rem;
        background-color: var(--c-white);

        @include is-dark-child {
            color: var(--c-white);
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-portrait-up) {
            width: 50%;
            padding-block: 4rem;
            border-block-end: 1px solid var(--c-line);
        }
    }

    &__caption {
        @extend %label;

        margin-block-end: 2rem;
    }

    &__picture-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background-color: transparent;

        @include for-size(tablet-portrait-up) {
            flex-direction: row-reverse;
            align-items: center;
            position: absolute;
            width: 50%;
            height: 100%;
        }
    }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__paragraph {
        @extend %html-format;

        margin-block-start: 2rem;
    }

    &__link-button-list {
        margin-block-start: 3rem;
    }

    &__image {
        object-fit: cover;
    }

    &__card-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;
        inset-block-start: -6rem;
        width: 100%;
        max-width: 19rem;
        pointer-events: none;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-end: -2rem;
        }
    }

    &__impact-card {
        pointer-events: all;
    }
}
