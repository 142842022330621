@import "src/styles";

.header-quick-links-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &__link {
        @extend %heading-4;

        margin-inline-end: 2rem;
        text-decoration: none;
        color: var(--c-white);

        &::before {
            content: unset;
        }

        &:hover,
        &:focus,
        &.active {
            text-decoration: underline;
            color: var(--c-primary);
        }
    }
}
