@import "src/styles";

.not-found-page {
    padding-block-start: var(--menu-height);

    &__wrapper {
        padding-block-start: 2.5rem;
    }

    &__content-wrapper {
        width: 100%;
        padding-block: 4rem;
        border: 1px solid var(--c-line);
        border-inline-end: none;
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 1.5);
        }
    }

    &__description {
        margin-block-start: 1rem;
    }

    &__link-button {
        margin-block-start: 2rem;
    }
}
