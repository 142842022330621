@import "src/styles";

.download-list {
    display: grid;
    row-gap: 2rem;
    position: relative;
    width: 100%;

    &--layout-horizontal {
        grid-template-columns: repeat(1, 1fr);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 50%);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, calc(100% / 3));
        }
    }

    &--layout-vertical {
        grid-template-columns: 1fr;
    }

    &__item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 1.5rem;
        }
    }
}
