@import "src/styles";

.video-section {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        margin-block-end: 2rem;
    }

    &__paragraph {
        margin-block-end: 2rem;
    }
}
