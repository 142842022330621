@import "src/styles";

.page-search-result-item {
    --c-page-search-result-accent: var(--c-blue);

    display: flex;
    flex-direction: column;
    position: relative;
    padding-block: 1rem;
    color: var(--c-blue);

    &:hover,
    &:focus-within {
        --c-page-search-result-accent: var(--c-primary);
    }

    @include for-size(tablet-portrait-up) {
        padding-inline-end: 2rem;
    }

    &__title {
        @extend %heading-4;

        margin-block-start: .75rem;
        color: var(--c-page-search-result-accent);
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
        }
    }

    &__category {
        order: -1;
        font-size: 1rem;
        color: var(--c-grey);
    }

    &__description {
        @extend %text-with-underline;

        margin-block-start: 1rem;
        padding-block-end: 1.5rem;
    }
}
