@import "src/styles";

.pdf-reader {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--c-white);

    &--is-loading {
        align-items: center;
        justify-content: center;
        aspect-ratio: 1.3;
    }

    &__wrapper,
    &__sizer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
    }

    &__document-wrapper {
        @extend %flex-align-center;

        flex-grow: 1;
    }

    &__page {
        max-width: 100%;
    }

    &__controls {
        margin-block-start: auto;
        justify-self: flex-end;
        width: 100%;
        height: 2rem;
    }
}
