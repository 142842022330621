@import "src/styles";

.financial-results-table {

    &__table {

        @include for-size(laptop-up) {
            overflow-x: hidden;
        }
    }

    &__table-head {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: table-header-group;
        }
    }

    &__header-row {

        @include is-dark-child {
            color: var(--c-primary);
        }
    }

    &__cell:nth-child(2) {

        @include for-size(tablet-landscape-up) {
            @include column-width(25rem);
        }
    }
}