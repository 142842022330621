.interactive-map {
    width: 100%;
    padding-block-end: 2rem;
    background-color: var(--c-blue-dark);

    &__details {
        // TODO: Enable this feature after going live (phase 2).
        // This maybe need to be redesigned/rediscussed with the Design Team and the client.
        // https://endeavour-nl.atlassian.net/browse/TKH-282?focusedCommentId=168487
        display: none;
    }
}
