@import "src/styles";

$component: "visual-carousel-slide";

.#{$component} {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 100%;
    height: 100%;
    color: var(--c-white);

    &::before {
        @extend %click-extend;

        content: "";
        display: block;
        z-index: 10;
        background: linear-gradient(
            90deg,
            rgba(var(--c-black-rgb), 0.80) .5%,
            rgba(var(--c-blue-dark-rgb), 0.60) 21%,
            rgba(var(--c-black-rgb), 0) 62%,
        );
        pointer-events: none;
    }

    &--align-right {

        &::before {
            background: linear-gradient(
                270deg,
                rgba(var(--c-black-rgb), .80) .5%,
                rgba(var(--c-blue-dark-rgb), .60) 21%,
                rgba(var(--c-black-rgb), 0) 62%,
            );
        }

        .#{$component}__text-wrapper {
            align-items: flex-end;
            margin-inline-start: auto;
            text-align: right;
        }
    }

    &__text-wrapper {
        position: relative;
        z-index: 20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        min-height: 40rem;
        height: 100%;
        padding: 2rem;
        padding-block-end: 6rem;

        @include for-size(tablet-landscape-up) {
            padding: 4rem;
        }
    }

    &__title {
        margin-block-start: auto;
        font-size: 2.5rem;
        font-weight: var(--fw-bold);
        line-height: 1;

        @include for-size(tablet-landscape-up) {
            font-size: 4rem;
        }
    }

    &__intro,
    &__description {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            max-width: 75%;
        }

        @include for-size(tablet-landscape-up) {
            max-width: 50%;
        }
    }

    &__intro {
        margin-block-start: 2.5rem;
        font-size: 2rem;
        line-height: 1.125;

        @include for-size(tablet-landscape-up) {
            margin-block-start: 5rem;
        }
    }

    &__description {
        margin-block-start: 1rem;
        margin-block-end: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-block-end: 5rem;
        }
    }

    &__label {
        @extend %label;

        margin-block-start: auto;
        color: var(--c-primary);
    }

    &__picture {
        @extend %click-extend;
    }

    &__image {
        object-fit: cover;
    }
}
