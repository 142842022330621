@import "src/styles";

.page-intro {

    &__wrapper {
        width: 100%;
        border: 1px solid var(--c-line);
        border-block-end: 0;
        border-inline-end: 0;
        margin-inline: auto;
        padding-block: 4rem;
        background: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            color: var(--c-white);
            background: var(--c-blue-dark);
        }

        @include for-size(tablet-landscape-up) {
            width: calc((100% / 6) * 4);
        }
    }

    &__title {

        h1, h2, h3, h4 {
            @extend %heading-1-unique;
        }
    }

    &__paragraph {
        margin-block-start: 2rem;
    }
}
