@import "src/styles";

.presentation-controls {
    display: flex;
    justify-content: center;
    gap: .625rem;
    width: 100%;
    padding: .25rem;
    color: var(--c-white);
    background-color: var(--c-blue);

    @include is-dark-child {
        background-color: var(--c-blue-dark);
    }

    &__prev-button,
    &__next-button {
        height: 100%;
        aspect-ratio: 1;
        padding: 0;
        color: var(--c-white);
        background: transparent;

        &:hover,
        &:focus {
            color: var(--c-primary);
        }

        &:disabled {
            cursor: not-allowed;
            opacity: .5;
            color: var(--c-white);
            background-color: transparent;
        }
    }
}
