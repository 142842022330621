@font-face {
    font-family: "Nimbus Sans L";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../typography/nimbus-sans-l_regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nimbus Sans L";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../typography/nimbus-sans-l_italic.ttf") format("truetype");
}

@font-face {
    font-family: "Nimbus Sans L";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../typography/nimbus-sans-l_bold.ttf") format("truetype");
}
