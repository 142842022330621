@import "../helpers";

%label {
    font-size: 1rem;
    font-weight: var(--fw-bold);
    line-height: 1.375;
    color: currentColor;
}

%input-label {
    @extend %label;

    font-size: 1rem;
    font-weight: var(--fw-regular);
    line-height: 1.5;
}

%heading {
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    word-break: break-word;
    hyphens: manual;
    color: currentColor;

    strong,
    em {
        font-weight: inherit;
        font-style: normal;
        color: var(--c-primary);
    }

    @include for-size(tablet-portrait-up) {
        hyphens: none;
    }
}

%heading-1-unique {
    @extend %heading;

    font-size: 3rem;
    font-weight: var(--fw-bold);
    line-height: 1.33;

    @include for-size(tablet-portrait-up) {
        font-size: 4.5rem;
        line-height: 1;
    }
}

%heading-1 {
    @extend %heading;

    font-size: 3rem;

    @include for-size(tablet-portrait-up) {
        font-size: 3.25rem;
        line-height: 1.23;
    }
}

%heading-2 {
    @extend %heading;

    font-size: 2.5rem;
    line-height: 1.2;

    @include for-size(tablet-portrait-up) {
        font-size: 3rem;
        line-height: 1.16;
    }
}

%heading-3 {
    @extend %heading;

    font-size: 2rem;
}

%heading-4 {
    @extend %heading;

    font-size: 1.375rem;
    line-height: 1.5;
    color: var(--c-primary);
}

%highlighted-paragraph {
    font-size: 1.375rem;
    font-weight: var(--fw-regular);
    line-height: 1.5;
}

%important-number {
    font-size: 2.625rem;
    line-height: 1.14;
}

%text-with-underline {
    position: relative;
    padding-block-end: .75rem;

    &::after {
        content: "";
        display: block;
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 0;
        width: 2rem;
        height: 1px;
        background-color: var(--c-accent);
    }
}

%sub-text {
    font-size: .875rem;
    line-height: 1.6;
}
