@import "src/styles";

.parallax-line-section-header {
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 20;
    height: fit-content;
    min-height: 100vh;
    min-height: 100lvh;

    @include is-dark-child {
        color: var(--c-white);
    }

    &__content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4rem;
        border-inline: 1px solid var(--c-line-dark);
        padding-block-end: 2rem;

        @include for-size(tablet-portrait-up) {
            padding-block-end: 5.5rem;
            padding-inline-end: 14rem;
        }
    }

    &__title h1 {
        @extend %heading-1-unique;

        color: var(--c-white);
    }

    &__caption {
        @extend %label;
    }
}
