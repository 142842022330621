@import "src/styles";

.cookie-settings {
    @extend %flex-align-center;

    position: fixed;
    inset-block-end: 0;
    padding-block-end: 1.5rem;
    width: 100%;
    transition: translate .3s ease;
    translate: 0 100%;

    &--is-revealed {
        translate: 0 0;
    }

    &__wrapper {
        width: 100%;
        max-width: var(--wrapper-width);
        padding-inline: var(--wrapper-padding);
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-block: 1.5rem;
        padding-inline: 1.5rem;
        color: var(--c-white);
        background-color: var(--c-blue-medium);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: flex-end;
            gap: 4.5rem;
            padding-inline: 4rem;
        }
    }

    &__description {
        font-size: 1rem;
        line-height: 1.5;
    }

    &__link {
        color: var(--c-primary);
        text-decoration: none;
    }

    &__accept-button {
        @extend %underscore-button;

        width: max-content;
        padding: 0;
    }
}
