@import "src/styles";

.articles-overview {

    &__wrapper {
        border-block: 1px solid var(--c-line);
        padding-block-start: 2rem;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    &__no-results-wrapper,
    &__list {
        margin-block-start: 4rem;
    }

    &__no-results {

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__pagination {
        justify-content: center;
        margin-block-start: 3rem;
        margin-inline: auto;
        padding-block: 2rem;
        border: 1px solid var(--c-line);
        border-inline-end: none;
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 1.5 + 1px);
            border-inline-start: 1px solid var(--c-line);
        }

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 3 + 1px);
        }
    }
}
