@import "src/styles";

$component: financial-results-table-row;

.#{$component} {
    font-weight: var(--fw-regular);

    &--no-release-date {

        .#{$component}__cell:nth-child(n+2) {
            color: var(--c-grey);
        }
    }

    &--no-media {

        .#{$component}__cell:nth-child(3) {
            @include column-width(30rem);
        }
    }
}