@import "src/styles";

.newsletter-form {
    width: 100%;

    &__form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }

    &__title-wrapper {
        display: flex;
        align-items: baseline;
        gap: 1rem;
    }

    &__title-icon {
        min-width: 2rem;
    }

    &__title {
        @extend %heading-3;
    }

    &__submit-button {
        @extend %underscore-button;

        justify-self: flex-end;
        margin-block-start: 1rem;
    }

    &__recaptcha-disclaimer,
    &__recaptcha-error {
        margin-block-start: 1rem;
    }
}
