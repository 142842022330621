@import "src/styles";

.background-illustration {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 0;
    pointer-events: none;

    &__picture {
        height: fit-content;
        background-color: transparent;

        @include is-dark-child {
            background-color: transparent;
        }
    }
}
