@import "src/styles";

$component: shareholder-meetings-archive-year;

.#{$component} {

    &[open],
    &__details[open] {
        padding-block-end: 0;
    }

    &__wrapper {

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 1.5);
        }
    }

    &__details {

        &:not([open]) .#{$component}__details-summary {
            border-block-end: unset;
        }
    }

    &__details-summary {

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
        }
    }

    &__content-column {
        margin-block-start: -1px;
        padding-block-start: 2rem;
        padding-inline: 0;
    }

    &__table {
        border-block: unset;

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
        }
    }
}
