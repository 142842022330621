@import "src/styles";

$component: "checkbox";

.#{$component} {
    @extend %input-label;

    display: flex;
    align-items: center;
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked ~ .#{$component}__box .#{$component}__icon {
            opacity: 1;
            scale: 1;
        }
    }

    &__box {
        @extend %flex-align-center;
        @include square(1.875rem);

        position: relative;
        overflow: clip;
        border: 1px solid currentColor;
    }

    &__icon {
        position: relative;
        z-index: 20;
        width: 1.125rem;
        color: var(--c-accent);
        transition: opacity .1s ease, scale .1s ease;
        opacity: 0;
        scale: .75;
    }

    &__label {
        margin-inline-start: 1rem;
    }
}
