@import "src/styles";

$component: text-columns;

.#{$component} {

    &--col-2 &__content {

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 3);

            .#{$component}__outer-link-wrapper {
                width: 200%;
                inset-inline-end: -200%;
            }
        }
    }

    &--col-4 {

        .#{$component}__content {

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 1.5);
            }
        }

        .#{$component}__columns {

            @include for-size(tablet-portrait-up) {
                column-count: 1;
                column-fill: auto;
            }
        }
    }

    &--col-2-2 {

        .#{$component}__content {

            @include for-size(tablet-landscape-up) {
                width: calc(100% / 1.5);
            }
        }

        .#{$component}__columns {

            @include for-size(tablet-portrait-up) {
                column-count: 2;
            }
        }
    }

    &--col-3-3 &__columns {

        @include for-size(tablet-portrait-up) {
            column-count: 2;
        }
    }

    &--col-2-2-2 &__columns {

        @include for-size(tablet-portrait-up) {
            column-count: 2;
        }

        @include for-size(tablet-landscape-up) {
            column-count: 3;
        }
    }

    &__content {
        padding-block: 4rem;
        border: 1px solid var(--c-line);
        border-inline-end: none;
        background-color: var(--c-white);

        @include is-dark-child {
            color: var(--c-white);
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-portrait-up) {
            width: calc(100% - 1px);
        }
    }

    &__title {
        @extend %heading-1;
    }

    &__intro {
        @extend %highlighted-paragraph;
    }

    &__title + &__intro {
        margin-block-start: 2rem;
    }

    &__title + &__columns,
    &__intro + &__columns {
        margin-block-start: 2rem;
    }

    &__columns {
        position: relative;
        column-fill: balance;

        @include for-size(tablet-portrait-up) {
            width: calc(100% - 1px);
        }
    }

    &__paragraph {
        @extend %html-format;

        display: block;
        padding-inline-end: 2rem;

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 3rem;
        }
    }

    &__link-button {
        margin-block-start: 3rem;
    }

    &__outer-link-wrapper {

        @include for-size(tablet-landscape-up) {
            display: flex;
            align-items: center;
            position: absolute;
            inset-block-start: 50%;
            inset-inline-end: -50%;
            width: 50%;
            height: 0;
        }
    }
}
