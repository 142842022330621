@import "src/styles";

.solutions-carousel-skeletons {

    &__item {
        padding-inline-end: 2rem;
    }

    &__image {
        width: 100%;
        aspect-ratio: 16 / 9;
        background-color: var(--c-grey-light);

        @include is-dark-child {
            background-color: var(--c-blue-medium);
        }
    }

    &__text-wrapper {
        margin-block-start: 2rem;
        padding-inline-end: 2rem;
    }

    &__title {
        font-size: 1.375rem;
    }

    &__link-button {
        width: 8rem;
        margin-block-start: 2.5rem;
    }
}
