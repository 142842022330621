.footer-skeletons {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 20;
    width: 100%;
    border-inline-end: 1px solid transparent;

    &__title {
        color: var(--c-primary);
    }

    &__list {
        margin-block-start: 1rem;
    }

    &__item {

        & + & {
            margin-block-start: .75rem;
        }
    }

    &__item-line {
        font-size: .875rem;
    }
}
