@import "./mixins";

@mixin column-child {
    border-block-start: none;
    border-block-end: var(--grid-line);
    border-inline-start: var(--grid-line);
    border-inline-end: none;
    background: var(--grid-background);
}

@mixin two-columns-child {
    @include column-child;

    &:nth-child(-n+2) {
        border-block-start: var(--grid-line);
    }
}

@mixin three-columns-child {
    @include column-child;

    &:nth-child(-n+3) {
        border-block-start: var(--grid-line);
    }

    &:nth-child(2n) {
        border-inline-end: none;
    }
}

@mixin six-columns-child {
    @include column-child;

    &:nth-child(-n+6) {
        border-block-start: var(--grid-line);
    }

    &:nth-child(3n) {
        border-inline-end: unset;
    }

    &:nth-child(6n) {
        border-inline-end: var(--grid-line);
    }
}

@mixin one-column-line-grid($child-class-name: list-item) {
    --grid-background: var(--c-white);
    --grid-line: 1px solid var(--c-line);

    @include is-dark-child {
        --grid-line: 1px solid var(--c-line-dark);
        --grid-background: var(--c-blue-dark);
    }

    .#{$child-class-name} {
        border: var(--grid-line);
        background-color: var(--grid-background);

        &:not(:first-child) {
            border-block-start: unset;
        }
    }
}

@mixin two-columns-line-grid($child-class-name: list-item) {
    --grid-background: var(--c-white);
    --grid-line: 1px solid var(--c-line);

    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include is-dark-child {
        --grid-line: 1px solid var(--c-line-dark);
        --grid-background: var(--c-blue-dark);
    }

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    .#{$child-class-name} {
        border-block-end: var(--grid-line);
        border-inline-start: var(--grid-line);
        background-color: var(--grid-background);

        &:first-child {
            border-block-start: var(--grid-line);
        }

        @include for-size(tablet-portrait-up) {

            @include two-columns-child;
        }
    }
}

@mixin three-columns-line-grid($child-class-name: list-item) {
    @include two-columns-line-grid($child-class-name);

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    .#{$child-class-name} {

        @include for-size(tablet-landscape-up) {

            @include three-columns-child;
        }
    }
}

@mixin six-columns-line-grid($child-class-name: list-item) {
    --grid-background: var(--c-white);
    --grid-line: 1px solid var(--c-line);

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include is-dark-child {
        --grid-line: 1px solid var(--c-line-dark);
        --grid-background: var(--c-blue-dark);
    }

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(6, 1fr);
    }

    .#{$child-class-name} {
        @include two-columns-child;

        @include for-size(tablet-portrait-up) {

            @include three-columns-child;
        }

        @include for-size(tablet-landscape-up) {

            @include six-columns-child;
        }
    }
}

@mixin four-columns-line-grid($child-class-name: list-item) {
    @include two-columns-line-grid($child-class-name);

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(4, 1fr);
    }

    .#{$child-class-name} {

        @include for-size(tablet-landscape-up) {

            &:nth-child(-n+4) {
                border-block-start: var(--grid-line);
            }

            &:nth-child(2n),
            &:nth-child(3n) {
                border-inline-end: none;
            }

            &:nth-child(4n) {
                border-inline-end: var(--grid-line);
            }
        }
    }
}
