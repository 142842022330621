@import "src/styles";

.year-selector {

    &__label {
        @extend %label;

        color: var(--c-blue);

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__buttons {
        display: flex;
        gap: 2rem;
        width: 100%;
        margin-block-start: .5rem;
        padding-block: .5rem;
        overflow-x: auto;
        overflow-y: hidden;

        @include for-size(tablet-portrait-up) {
            gap: 4rem;
        }
    }
}
