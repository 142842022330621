@import "src/styles";

.key-figure {
    padding-inline-end: 0;

    &:last-of-type {
        padding-inline-end: 0;
    }

    @include for-size(tablet-portrait-up) {
        padding-inline-end: 2rem;
    }

    &__amount {
        @extend %important-number;

        display: flex;
        align-items: center;
        height: 3rem;

        .ticker-view {
            margin: unset;
        }
    }

    &__label {
        margin-block-start: .5rem;
        color: var(--c-primary);

        @include is-dark-child {
            color: var(--c-accent);
        }
    }
}
