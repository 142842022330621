@import "src/styles";

.contact-investor-relations {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 0;
        }
    }

    &__title,
    &__intro {
        padding-inline-end: 2rem;
    }

    &__title {
        @extend %heading-1;

        strong {
            display: block;
            color: var(--c-blue-dark);
        }
    }

    &__contact-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__cards-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-self: flex-end;
        margin-block-start: auto;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
        }
    }

    &__card-wrapper {
        padding-inline-end: 2rem;
    }

    &__card {
        min-height: 100%;
    }

    &__press-release-form {
        width: 100%;
    }
}
