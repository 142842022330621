@import "src/styles";

.video-embed {
    @extend %flex-align-center;

    &__iframe {
        width: 100%;
        max-height: 80vh;
        aspect-ratio: 16 / 9;
        border: none;
    }
}
