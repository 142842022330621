@import "src/styles";

.shareholder-documents {

    &__details {

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 1.5);
        }
    }

    &__details-summary {

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
        }
    }

    &__table {
        border-block-start: unset;

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
        }
    }
}
