@import "src/styles";

.parallax-line-section {
    --grid-lines-height: 100%;

    width: 100%;

    // This is a gsap fix for the parallax effect. I apologize profusely for using !important.
    &:not(&--is-absolute) {
        transform: unset !important;
    }

    &--is-absolute &__image-and-svg-wrapper {
        position: absolute;
    }

    &__image-and-svg-wrapper {
        @extend %click-extend;

        position: fixed;
        height: 100vh;
        height: 100lvh;
        margin: 0 auto;
    }

    &__image-picture,
    &__svg-picture-wrapper,
    &__svg-picture {
        width: 100%;
        height: 100%;
    }

    &__image-picture {
        position: absolute;
    }

    &__svg-picture {
        display: block;
    }

    &__svg-picture-wrapper {
        position: relative;
        overflow: hidden;
        opacity: 1;
        background: var(--c-blue-dark);
    }

    &__image,
    &__svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__darken,
    &__gradient-top,
    &__gradient-bottom,
    &__lines-wrapper {
        @extend %click-extend;
    }

    &__lines-wrapper {
        height: var(--grid-lines-height);
    }

    &__lines {
        position: absolute;
        border-block-end: 1px solid var(--c-line-dark);
        color: var(--c-line-dark);
    }

    &__darken {
        opacity: 0;
        background: var(--c-blue-dark);
    }

    &__gradient-top {
        inset-block-start: 100%;
        background: linear-gradient(0deg, rgba(var(--c-blue-dark-rgb), 0) 50%, rgba(var(--c-blue-dark-rgb), .75) 85%);
    }

    &__gradient-bottom {
        inset-block-start: calc(100% - 10%);
        height: 10%;
        background: linear-gradient(180deg, rgba(var(--c-blue-dark-rgb), 0) 0%, rgba(var(--c-blue-dark-rgb), 1) 100%);
    }
}
