@import "src/styles";

.map-cta-circle {
    --animation-duration: 6s;
    --size: 15rem;
    --ease: cubic-bezier(.69, -0.02, .25, 1);

    @extend %flex-align-center;

    position: relative;
    width: 0;
    height: 0;

    &__overlay,
    &__circle {
        position: absolute;
        min-width: var(--size);
        aspect-ratio: 1;
        border-radius: 50%;
        scale: 0;
    }

    &__circles {
        @extend %flex-align-center;

        position: absolute;
    }

    &__overlay {
        background-color: var(--c-white);
        opacity: 0;
        animation: scale-in var(--animation-duration) var(--ease) infinite,
            pulse var(--animation-duration) var(--ease) infinite;
        mix-blend-mode: overlay;

        @include is-dark-child {
            background-color: var(--c-blue);
            mix-blend-mode: screen;
        }
    }

    &__circle {
        border: 1px solid var(--c-primary);
        animation: scale-in-large var(--animation-duration) var(--ease) infinite,
            fade-out-splash var(--animation-duration) var(--ease) infinite;
    }

    &__circle:nth-child(1) {
        animation: scale-in var(--animation-duration) var(--ease) infinite,
            fade-out-splash var(--animation-duration) var(--ease) infinite;
    }

    &__circle:nth-child(2) {
        min-width: calc(var(--size) * .9);
        animation-timing-function: cubic-bezier(.46, -0.01, .99, .53);
    }

    &__circle:nth-child(3) {
        min-width: calc(var(--size) * .6);
        animation-timing-function: cubic-bezier(.53, 0, .88, .34);
    }

    &__circle:nth-child(4) {
        min-width: calc(var(--size) * .45);
        animation-timing-function: cubic-bezier(.7, 0, .88, .34);
    }
}
