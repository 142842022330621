@import "src/styles";

.tab-button {
    position: relative;
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);
    }

    &--is-active,
    &:hover,
    &:focus {
        color: var(--c-accent);

        @include is-dark-child {
            color: var(--c-accent);
        }
    }

    &--is-active:after {
        content: "";
        display: block;
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 1rem;
        width: calc(100% - 2rem);
        height: 2px;
        background: currentColor;
    }

    &__button {
        padding-block: .25rem;
        padding-inline: 1rem;
        font-size: 2rem;
        font-weight: var(--fw-regular);
        line-height: 1;
        white-space: nowrap;
        color: inherit;
        background-color: transparent;
    }
}
