@import "src/styles";

.shareholder-meetings-archive {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-2;
    }

    &__year:first-of-type {
        margin-block-start: 2rem;
    }

    &__year[open] + &__bottom-border {
        width: 100%;
        border-block-end: 1px solid var(--c-line);

        @include is-dark-child {
            border-block-end: 1px solid var(--c-line-dark);
        }

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 1.5);
        }
    }
}
