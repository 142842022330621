@import "src/styles";

$component: "heading-header";

.#{$component} {
    position: relative;
    padding-block: 12.5rem 6.25rem;

    @include is-dark-parent {
        color: var(--c-white);
        background-color: var(--c-blue-dark);
    }

    @include for-size(tablet-portrait-up) {
        padding-block-start: 16rem;
    }

    &--has-wide-title {
        padding-block: 8rem 6.25rem;

        @include for-size(tablet-portrait-up) {
            padding-block-start: 8rem;
        }

        .#{$component}__title-wrapper {

            @include for-size(tablet-portrait-up) {
                max-width: unset;
            }
        }
    }

    &__wrapper {
        position: relative;
        z-index: 20;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 2rem;

        @include for-size(tablet-portrait-up) {
            max-width: 50%;
        }
    }

    &__subtitle {
        @extend %label;
    }

    &__link-button {
        margin-block-start: 2rem;
    }

    &__grid-lines {
        z-index: 10;
    }
}
