@import "src/styles";

.pagination {
    display: flex;
    gap: 2rem;
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__input {
        height: 2.5rem;

        .select__control {
            padding-inline: .75rem !important;
            font-weight: var(--fw-bold);
        }

        .select__value-container {
            padding-inline-end: .5rem !important;
        }
    }

    &__button {
        @include circle(2.5rem);

        border: 1px solid var(--c-blue);
        padding: 0;
        color: var(--c-blue);
        background: none;

        &:hover,
        &:focus {
            color: var(--c-white);
            background-color: var(--c-blue);
        }

        &:disabled {
            color: var(--c-blue);
            background: none;
            opacity: .5;
        }

        @include is-dark-child {
            color: var(--c-white);
            border-color: var(--c-white);

            &:hover,
            &:focus {
                color: var(--c-blue-dark);
                background-color: var(--c-white);
            }

            &:disabled {
                color: var(--c-white);
                background: none;
                opacity: .5;
            }
        }
    }
}
