@import "src/styles";

.solution-detail-body {

    &__wrapper {

        @include is-dark-child {
            color: var(--c-white);
        }

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 50% calc(50% - 2rem);
            justify-content: space-between;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: calc(100% / 1.5) calc(100% / 3 - 2rem);
        }

        @include for-size(laptop-up) {
            grid-template-columns: calc(100% / 1.5) calc(100% / 3 - 4rem);
        }
    }

    &__content-column {
        padding: 0;
    }

    &__cards-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }
}
