@import "src/styles";

.home-quick-link-content {
    @extend %underscore-button;

    display: flex;
    align-items: center;
    width: 100%;
    min-height: 5rem;
    padding-block: .5rem;
    padding-inline-end: 1rem;
    font-weight: var(--fw-bold);

    &:hover,
    &:focus {
        color: unset;
    }

    @include for-size(tablet-portrait-up) {
        width: 12.5rem;
        min-height: 12.5rem;
    }

    &__label {
        max-width: calc(100% - 2.5rem);
        word-break: break-word;
    }
}