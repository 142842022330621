%button {
    @extend %flex-align-center;

    margin: 0;
    padding-block: .75rem;
    padding-inline: 1.5rem;
    font: inherit;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    line-height: 1;
    color: var(--c-black);
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-primary);

    &:disabled {
        color: var(--c-white);
        cursor: not-allowed;
        background-color: var(--c-grey);
    }
}

%button--no-background {
    background: none;

    &[href]:hover,
    &[href]:focus {
        background: none;
    }

    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }
}

%underscore-button {
    display: block;
    width: fit-content;
    padding-block: 1.125rem .75rem;
    padding-inline: 0 2.25rem;
    font-weight: var(--fw-bold);
    line-height: 1.5;
    text-align: start;
    color: currentColor;
    text-decoration: none;
    background: none;

    &:hover,
    &:focus {
        color: var(--c-accent);
    }

    &::before {
        content: "";
        display: inline-block;
        width: 1.875rem;
        height: 1px;
        margin-inline-end: .625rem;
        background-color: var(--c-accent);
    }
}
