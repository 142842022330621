@import "src/styles";

.goal-card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    padding-block-end: 3rem;

    &__caption {
        @extend %label;
    }

    &__paragraph {
        @extend %highlighted-paragraph;
    }
}
