@import "src/styles";

.calendar {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        font-size: 2rem;
    }

    &__no-results-wrapper {
        padding-block: 2rem;
    }

    &__list-wrapper {

        & + & {
            margin-block-start: 4rem;

            @include for-size(tablet-portrait-up) {
                margin-block-start: 8rem;
            }
        }
    }

    &__list {
        margin: 0;
        margin-block-start: 2rem;
        padding: 0;
        background-color: var(--c-white);

        @include is-dark-child {
            background-color: var(--c-dark);
        }
    }

    &__list-item {
        list-style-type: none;
    }

    &__list-item:last-child &__event {
        border-block-end: 1px solid var(--c-line);

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    &__event {
        border: 1px solid var(--c-line);
        border-block-end: none;
        border-inline-end: none;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }
}
