@import "src/styles";

.highlighted-download-card {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: calc(100% / 3) calc(100% / 1.5);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: calc(100% / 3) 50%;
        }
    }

    &__paragraph {
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            padding-inline-start: 4rem;
        }
    }
}
