@import "src/styles";

.grid-lines {
    @extend %click-extend;

    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    inset-inline: calc((100% - var(--wrapper-width)) / 2);
    width: var(--wrapper-width);
    padding-inline: var(--wrapper-padding);
    color: var(--c-line);

    @include for-size(laptop-up) {
        padding-inline: 0;
    }

    @include is-dark-child {
        color: var(--c-line-dark);
    }

    &__column {
        position: relative;
        border-inline-start: 1px solid currentColor;

        &:last-of-type::after {
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: -1px;
            width: 1px;
            height: 100%;
            background-color: currentColor;
        }
    }
}
