@import "src/styles";

.textarea-input {
    @extend %input;

    min-height: 6em;
    resize: vertical;

    &--has-error {
        border-block-end-color: var(--c-negative);

        @include is-dark-child {
            border-block-end-color: var(--c-negative);
        }
    }
}
