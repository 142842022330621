@import "src/styles";

.filter-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem;
    color: var(--c-black);
    cursor: pointer;

    @include is-dark-child {
        color: var(--c-white);
    }

    &__input {
        position: relative;
        width: 1.875rem;
        height: 1.875rem;
        margin: 0;
        appearance: none;
        cursor: pointer;

        &:checked::before {
            opacity: 1;
        }

        &::before {
            content: '';
            opacity: 0;
        }
    }
    &--tkh-group,
    &--smart-vision,
    &--smart-connectivity,
    &--smart-manufacturing {
        border: 1px solid var(--c-primary);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.25rem;
            height: 1.25rem;
            transform: translate(-50%, -50%);
            transition: opacity;
            opacity: .15;
        }

        &:hover:not(:checked)::before {
            opacity: .25;
        }
    }

    &--tkh-group { border-color: var(--c-primary); }
    &--smart-vision { border-color: var(--c-green); }
    &--smart-connectivity { border-color: var(--c-blue-sky); }
    &--smart-manufacturing { border-color: var(--c-blue-light); }

    &--tkh-group::before { background-color: var(--c-primary); }
    &--smart-vision::before { background-color: var(--c-green); }
    &--smart-connectivity::before { background-color: var(--c-blue-sky); }
    &--smart-manufacturing::before { background-color: var(--c-blue-light); }
}
