@import "src/styles";

.dialog {
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    margin: 2rem;
    padding-block: 4rem;
    padding-inline: 2rem;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--c-white);

    @include for-size(tablet-landscape-up) {
        padding: 3rem;
    }

    &[open],
    &[open]::backdrop {
        animation: fade-in .3s ease;
    }

    &--is-hidden[open],
    &--is-hidden[open]::backdrop {
        animation: fade-out .3s ease forwards;
    }

    &--has-interactive-backdrop::backdrop {
        cursor: pointer;
    }

    &::backdrop {
        background-color: rgba(var(--c-full-black-rgb), .5);
    }

    &__close-button {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 20;
        padding: 1rem;
    }

    &__close-icon {
        width: 1rem;
        height: 1rem;
    }
}
