@import "src/styles";

$component: "menu-search-input";

.#{$component} {
    --expanded-width: 36rem;
    --collapsed-width: 2.5rem;

    display: flex;
    width: var(--collapsed-width);
    overflow: clip;
    transition: width var(--menu-animation-duration) ease;

    &--is-expanded {
        width: var(--expanded-width);

        .#{$component}__input {
            opacity: 1;
            pointer-events: all;
        }

        .#{$component}__input-border,
        .#{$component}__close-button {
            translate: 0 0;
        }
    }

    &--is-animation-disabled {
        transition: none;

        .#{$component}__input,
        .#{$component}__input-border,
        .#{$component}__close-button {
            transition: none;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        width: var(--expanded-width);
    }

    &__close-button,
    &__open-button {
        background-color: transparent;

        &:hover,
        &:focus {
            color: var(--c-accent);
        }
    }

    &__close-button,
    &__input {
        padding-block: .5rem;
        padding-inline: 1rem;
        font-weight: var(--fw-regular);
    }

    &__open-button {
        padding-block: 1.5rem;
        padding-inline: 0 1rem;
        color: currentColor;
    }

    &__open-button-icon {
        @include icon-size(1.5rem);
    }

    &__input {
        border: 0;
        padding-inline: .5rem 0;
        pointer-events: none;
        transition: opacity var(--menu-animation-duration) ease;
        opacity: 0;

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 1rem;
        }
    }

    &__input-border {
        @extend %click-extend;

        display: block;
        top: unset;
        bottom: 0;
        height: 1px;
        background-color: currentColor;
        transition: translate var(--menu-animation-duration) ease;
        translate: var(--collapsed-width) 0;
    }

    &__close-button {
        display: flex;
        padding-inline-end: 0;
        color: currentColor;
        transition: translate var(--menu-animation-duration) ease;
        translate: var(--expanded-width) 0;
    }

    &__close-button-icon {
        @include icon-size(1.125rem);
    }
}
