@import "src/styles";

$component: "contact-person-form";

.#{$component} {
    position: relative;
    z-index: 20;

    &:last-child {
        margin-block-start: -4rem;
        translate: 0 calc(calc(var(--section-wrapper-padding) * 2) + 4rem);
        background-color: transparent;

        // Hide grid lines
        .#{$component}__wrapper::before {
            content: unset;
        }
    }

    &__title {
        @extend %heading-1;

        color: var(--c-blue-dark);

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: unset;
        }
    }

    &__contact-person-card {

        @include for-size(tablet-portrait-up) {
            margin-inline-end: 2rem;
        }
    }
}
