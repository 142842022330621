@import "src/styles";

.calendar-event {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-block: 1.5rem;
    padding-inline: 2rem;
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);
    }

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__date-wrapper {
        display: flex;
        align-items: baseline;
        gap: .5rem;
    }

    &__date,
    &__time {
        font-size: 1rem;
    }

    &__location {
        font-size: 1rem;
    }

    &__title {
        @extend %highlighted-paragraph;
    }

    &__link-button {
        margin-inline-start: auto;
        padding-inline-end: 0;
    }
}
