@import "src/styles";

.group-item {
    flex: 1;
    padding-block: 2rem;
    padding-inline-end: 1rem;
    height: 23rem;
    overflow-y: auto;
    border: 1px solid var(--c-line);
    border-block-start: none;
    border-inline-end: none;

    @include is-dark-child {
        border-color: var(--c-line-dark);
        background-color: var(--c-blue-dark);
    }

    @include for-size(tablet-landscape-up) {
        padding-inline-end: 2rem;
    }

    &::-webkit-scrollbar {
        width: 1px;
        margin-left: 1rem;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--c-line);
        border-radius: 10px;
    }

    &__title {
        font-weight: var(--fw-bold);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 1rem;
    }

    &__list-item {
        color: var(--c-black);

        @include is-dark-child {
            color: var(--c-white);
        }
    }
}
