@import "src/styles";

.press-release-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-block-start: 1rem;

    &__submit-button {
        @extend %underscore-button;

        justify-self: flex-end;
        margin-block-start: 1rem;
    }

    &__recaptcha-disclaimer,
    &__recaptcha-error {
        margin-block-start: 1rem;
    }
}
