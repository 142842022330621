@import "src/styles";

.financial-overview-table {
    width: 100%;

    &__cell {
        @include column-width(6rem);

        &:first-child {
            @include column-width(14rem);

            margin-inline-end: auto;
        }

        &:not(:last-child) {
            border: none;
        }

        @include for-size(tablet-portrait-up) {
            @include column-width(8rem);

            &:first-child {
                @include column-width(32rem);
            }
        }
    }

    &__body &__cell {
        min-height: 3.25rem;
    }
}
