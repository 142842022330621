.submit-button {
    display: flex;
    align-items: center;
    gap: .625rem;

    &:disabled {
        display: flex;
        align-items: center;
        color: var(--c-grey);
        background-color: inherit;

        &::before {
            content: unset;
        }

        &:hover {
            color: var(--c-grey);
        }
    }

    &--align-right {
        flex-direction: row-reverse;
    }

    &__icon {
        display: block;
        max-width: 1em;
        height: 1em;
        margin-inline: .4375rem;
        color: var(--c-primary);
    }
}
