@import "src/styles";

$component: "value-card";

.#{$component} {
    --value-card-animation-speed: .25s;
    --value-card-height: 30rem;
    --value-card-title-height: 4rem;

    display: flex;
    position: relative;
    overflow: hidden;

    @include for-size(tablet-portrait-up) {
        min-height: 100%;
        background: var(--c-blue);

        &:hover {

            .#{$component}__content {
                translate: 0 0;
            }

            .#{$component}__title-wrapper {
                translate: 0 calc(var(--value-card-height) * -1 + 4rem + var(--value-card-title-height));
            }

            .#{$component}__title {
                color: var(--c-blue);
            }

            .#{$component}__image {
                opacity: 1;
            }
        }
    }

    &__content {
        padding-block: 2rem;
        padding-inline: 1rem;
        flex-grow: 1;

        @include for-size(tablet-portrait-up) {
            color: var(--c-blue);
            background: var(--c-primary);
            translate: 0 100%;
            transition: translate var(--value-card-animation-speed) ease-out;
        }
    }

    &__title,
    &__content-title {
        @extend %heading-1;
    }

    &__content-title {

        @include for-size(tablet-portrait-up) {
            visibility: hidden;
        }
    }

    &__title-wrapper {
        position: absolute;
        inset-block-end: 0;
        padding-block-end: 2rem;
        padding-inline: 1rem;
        width: 100%;
        transition: translate var(--value-card-animation-speed) ease-out;
    }

    &__title {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            color: var(--c-white);
            transition: color var(--value-card-animation-speed) ease-out;
        }
    }

    &__description {
        margin-block-start: 1.5rem;
    }

    &__image {
        @extend %click-extend;

        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            object-fit: cover;
            opacity: .75;
            transition: opacity var(--value-card-animation-speed) ease-out;
        }
    }
}
