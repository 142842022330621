@import "src/styles";

$component: "testimonials-carousel";

.#{$component} {
    --active-testimonial-index: 1;

    color: var(--c-blue);

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__header {
        padding-block-start: 3rem;
        padding-block-end: 2rem;
        border: 1px solid var(--c-line);
        border-inline-end: 0;
        background: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background: var(--c-blue-dark);
        }
    }

    &__title {
        @extend %heading-2;
    }

    &__sub-title {
        @extend %heading-3;

        margin-block-start: 1rem;
    }

    &__content-wrapper {
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: calc(100% / 3 - 1.5rem) calc(100% / 1.5 - 1.5rem);
        }
    }

    &__quotes-list {
        position: relative;
        padding-block: 2rem;
        height: var(--testimonials-carousel-active-quote-height);

        @include for-size(tablet-portrait-up) {
            height: var(--testimonials-carousel-max-height);
        }
    }

    &__quote-item {
        @extend %click-extend;

        inset-block-start: unset;
        inset-block-end: 0;
        display: flex;
        align-items: center;
        height: fit-content;
        opacity: 0;
        transition: opacity .3s ease-out;

        @include for-size(tablet-portrait-up) {
            height: 100%;
        }

        &--is-active {
            opacity: 1;
        }
    }

    &__carousel {
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
        }
    }

    &__navigation {
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 4rem;
        }
    }
}
