@import "src/styles";

$component: financial-results-row-mobile;

.#{$component} {

    &--is-subordinate {

        .#{$component}__cell:nth-child(2) {
            color: var(--c-grey);
        }
    }

    &:nth-child(5n) {
        margin-block-end: 2rem;
        border-block-end: 1px solid var(--c-blue-dark);
    }

    &:last-child {
        margin-block-end: 0;
    }

    &__cell {
        @include column-width(50%);

        &:first-child {
            font-weight: var(--fw-bold);
        }
    }
}
