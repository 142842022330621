@import "src/styles";

$component: "related-content";

.related-content {

    &--col-2 {

        .#{$component}__wrapper {
            grid-template-columns: 1fr 2fr;
        }

        .#{$component}__cards-list {
            @include two-columns-line-grid(related-content__cards-list-item);
        }
    }

    &__wrapper {

        @include for-size(tablet-portrait-up) {
            display: grid;
            justify-content: space-between;
            grid-template-columns: calc(100% / 3) 50%;
        }
    }

    &__title-wrapper {
        padding-block-end: 1.5rem;
    }

    &__title {

        h1, h2, h3, h4, h5 {
            @extend %heading-1;
        }

        strong {
            color: currentColor;
        }
    }

    &__cards-list {
        @include one-column-line-grid(related-content__cards-list-item);
    }
}
