@import "src/styles";

.highlighted-impact-card {

    @include is-dark-parent {
        color: var(--c-white);
        background-color: var(--c-blue-dark);
    }

    &__wrapper {
        border-block: 1px solid var(--c-line);
        padding-block: 2rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-wrap: wrap;
        }

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    &__column {
        width: 100%;
        padding-inline-end: 0;

        & + & {
            margin-block-start: 2rem;
        }

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 4rem;

            & + & {
                margin-block-start: 0;
            }
        }

        &:nth-child(1) {

            @include for-size(tablet-portrait-up) {
                width: 50%;
                padding-inline-end: 2rem;
            }

            @include for-size(tablet-landscape-up) {
                width: calc(100% / 3);
            }
        }

        &:nth-child(2) {

            @include for-size(tablet-portrait-up) {
                width: 50%;
            }

            @include for-size(tablet-landscape-up) {
                width: calc(41.65%);
            }
        }

        &:nth-child(3) {
            padding-inline-end: 0;

            @include for-size(tablet-portrait-up) {
                width: calc(100% / 3);
                translate: 1px 0;
            }

            @include for-size(tablet-landscape-up) {
                width: calc(25%);
            }
        }
    }

    &__intro {
        @extend %highlighted-paragraph;
    }

    &__link {
        margin-block-start: 3rem;
    }
}
