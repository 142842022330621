@import "src/styles";

.linked-in-card {

    &__title-wrapper {
        display: flex;
        gap: .75rem;
    }

    &__icon {
        @include icon-size(1.5rem);

        margin-block-start: .625rem;
    }

    &__title {
        @extend %heading-3;

        line-height: 1.5;
    }

    &__link-button {
        margin-block-start: 3rem;
    }
}
