@import "src/styles";

$component: "cache-buster";

.#{$component} {
    display: flex;
    justify-content: center;
    position: fixed;
    inset-block-end: 0;
    inset-inline-start: 0;
    z-index: 10;
    width: 100%;
    padding-block: 1.5rem;
    padding-inline: var(--wrapper-padding);
    translate: 0 100%;
    transition: translate .3s ease;

    &--is-revealed {
        translate: 0 0;
    }

    &--cookies-visible.#{$component}--is-revealed {
        translate: 0 negative(var(--cache-buster-offset));
    }

    &__card {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
        padding-block: 1.5rem;
        padding-inline: 1.5rem;
        color: var(--c-white);
        background-color: var(--c-blue-medium);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: baseline;
            gap: 4.5rem;
            width: unset;
            height: calc(6rem - 1.5rem);
            padding-inline: 4rem;
        }
    }

    &__description {
        line-height: 1;
    }

    &__button {
        @extend %underscore-button;

        width: max-content;
        padding: 0;
    }
}
