@import "src/styles";

.searchable-value-input {
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);
    }

    &:focus-within &__input-wrapper {
        border-color: currentColor;
    }

    &__input-field {
        padding-inline-start: .5rem;
    }

    &__result-list {
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border: 1px solid currentColor;
        border-block-start: none;
        box-shadow: var(--box-shadow);
    }

    &__result-option {

        & + & {
            border-block-start: 1px solid currentColor;
        }
    }
}
