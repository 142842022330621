@import "src/styles";

$component: quick-links-section;

.#{$component} {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {

        &--has-small-layout {

            .#{$component}__list {
                @include six-columns-line-grid(#{$component}__list-item);
            }
        }

        &--has-large-layout {
            display: grid;

            @include for-size(tablet-portrait-up) {
                grid-template-columns: 1fr 2fr;
            }

            .#{$component}__title {
                @extend %heading-2;

                padding-inline-end: 4rem;

                @include for-size(tablet-landscape-up) {
                    padding-inline-end: 10rem;
                }
            }

            .#{$component}__list {
                @include two-columns-line-grid(#{$component}__list-item);
            }

            .#{$component}__quick-link {
                width: 100%;
                font-size: 2rem;

                @include is-dark-child {
                    color: var(--c-white);
                }

                &:hover::after,
                &:focus-within::after {
                    translate: 0 0;
                }

                &::after {
                    content: "";
                }
            }

            .#{$component}__quick-link-link {
                z-index: 10;

                &:hover,
                &:focus {
                    color: var(--c-black);
                }

                &::after {
                    @extend %click-extend;

                    content: "";
                }
            }

            .#{$component}__description {
                pointer-events: none;
            }
        }
    }

    &__title {
        @extend %heading-3;

        margin-block-end: 2rem;
    }

    &__list-item {
        display: flex;
        align-items: flex-end;
    }

    &__quick-link {
        position: relative;
        justify-content: space-between;
        height: 100%;
        overflow: clip;

        &::after {
            @extend %click-extend;

            background-color: var(--c-primary);
            transition: translate .3s ease;
            translate: -100% 0;
        }
    }
}
