@import "src/styles";

$component: "selected-searchable-value";

.#{$component} {

    &__button {
        display: flex;
        align-items: center;
        gap: .5rem;
        padding: 0;
        padding-inline: 1rem .5rem;
        line-height: 1.5;
        text-align: start;
        border-radius: 1rem;
        background-color: var(--c-accent);

        &:enabled:hover,
        &:enabled:focus {

            .#{$component}__icon-wrapper {
                background-color: rgba(var(--c-white-rgb), .5);
            }
        }
    }

    &__label {
        padding-block: .5rem;
        padding-inline: 0;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(1.5rem);

        padding: .25rem;
    }

    &__icon {
        height: .75rem;
    }
}
