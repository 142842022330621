.radio-list {
    display: block;

    &__label {
        justify-content: flex-start;
        margin-block-end: .5rem;
    }

    &__option-wrapper {

        &--has-error {
            padding-inline-start: .5rem;
            border-inline-start: 2px solid var(--c-negative);
        }
    }

    &__radio {
        padding-block: .375rem;

        &:first-of-type {
            padding-block-start: 0;
        }

        &:last-of-type {
            padding-block-end: 0;
        }
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
