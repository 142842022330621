@import "typography";

%nav-item {
    display: flex;
    align-items: center;
    font-size: 2.25rem;
}

%nav-item-button {
    position: relative;
    display: inline-block;
    border: 0;
    padding-block: .625rem;
    padding-inline-start: 0;
    padding-inline-end: calc(var(--navigation-item-line-width) + .625rem);
    font-size: inherit;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
    background: none;

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        inset-block-end: .75rem;
        inset-inline-start: .625rem;
        width: var(--navigation-item-line-width);
        height: 1px;
        background-color: var(--c-accent);
        opacity: 0;
        transition: opacity .25s ease-out;
    }

    &:hover::before,
    &:focus::before {
        opacity: 1;
    }
}

%nav-item-label {
    display: inline-block;
    position: relative;
    z-index: 20;
    min-width: calc(var(--navigation-item-line-width) + .625rem);
    padding-inline-start: .5rem;
    background: var(--c-blue);
    transition: transform .25s ease-out;
}
