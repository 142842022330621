@import "src/styles";

$component: navigation;

.#{$component} {
    --navigation-level: 0;
    --navigation-transition-duration: 300ms;
    --tablet-navigation-list-width: 21rem;
    --desktop-navigation-list-width: 25rem;
    --desktop-navigation-padding-inline-end: 11rem;

    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    height: 100dvh;
    overflow-x: hidden;
    transition: transform var(--navigation-transition-duration) ease-out;

    @include for-size(tablet-portrait-up) {
        width: 100vw;
        background: none;
    }

    &--is-hidden {
        pointer-events: none;

        .#{$component}__wrapper {
            transform: translateX(100vw);

            @include for-size(tablet-portrait-up) {
                transform: none;
            }

            @include for-size(desktop-up) {
                transform: translateX(negative(calc(var(--desktop-navigation-list-width) * var(--navigation-level) - var(--desktop-navigation-padding-inline-end))));
            }
        }
    }

    &--is-main-route-active &__routes-list-wrapper:nth-child(2) {
        opacity: 1;
    }

    &--is-sub-route-active &__routes-list-wrapper:nth-child(3) {
        opacity: 1;
    }

    &--is-animation-disabled {
        transition: none;

        .#{$component}__wrapper,
        .#{$component}__routes {
            transition: none;
        }
    }

    &--is-routes-animation-disabled &__routes {
        transition: none;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        padding-block: 2rem;
        background: var(--c-blue);
        overflow-x: hidden;
        pointer-events: all;
        transition: transform var(--navigation-transition-duration) ease-out;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-inline-start: 100%;
            width: fit-content;
            min-width: 40rem;
            padding-block: 3rem;
            transform: translateX(negative(calc(var(--tablet-navigation-list-width) * var(--navigation-level))));
        }

        @include for-size(desktop-up) {
            inset-inline-start: calc(100% - var(--desktop-navigation-padding-inline-end));
            padding-inline-end: var(--desktop-navigation-padding-inline-end);
            transform: translateX(negative(calc(var(--desktop-navigation-list-width) * var(--navigation-level))));
        }
    }

    &__close-button {
        align-self: flex-end;
        width: fit-content;
        margin-inline: 2rem;
        padding: .5rem;
        color: var(--c-white);
        background: none;

        @include for-size(tablet-portrait-up) {
            align-self: unset;
        }

        @include for-size(desktop-up) {
            margin-inline: 3rem;
        }

        &:hover,
        &:focus {
            color: var(--c-accent);
        }
    }

    &__close-button-icon {
        @include icon-size(1rem);
    }

    &__routes {
        display: flex;
        width: fit-content;
        margin-block-start: 5rem;
        padding-inline-end: 5rem;
        transition: transform var(--navigation-transition-duration) ease-out;
        transform: translateX(calc(-100vw * calc(var(--navigation-level) - 1)));

        @include for-size(tablet-portrait-up) {
            transform: unset;
        }
    }

    &__routes-list-wrapper {
        width: 100vw;
        overflow: hidden;
        transition: .5s opacity ease-out;

        &:nth-child(n+2) {
            opacity: 0;
        }

        @include for-size(tablet-portrait-up) {
            width: var(--tablet-navigation-list-width);
        }

        @include for-size(desktop-up) {
            width: var(--desktop-navigation-list-width);
        }
    }

    &__routes-list,
    &__sub-routes-list {
        padding-inline: 2rem;
        color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            padding-inline: 2rem;
        }

        @include for-size(desktop-up) {
            padding-inline: 3rem;
        }
    }

    &__routes-list {

        &--is-sub-navigation {

            @include for-size(tablet-portrait-up) {
                padding-inline-start: 0;
            }
        }
    }

    &__sub-routes-list {
        margin-block-start: 4rem;
        color: var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            margin-block-start: 6.75rem;
        }
    }
}
