@import "src/styles";

.video {
    display: flex;
    align-items: center;
    position: relative;

    @include is-dark-child {
        border-color: var(--c-line-dark);
    }

    &:hover &__play-button,
    &:focus-within:focus-visible &__play-button,
    &--is-paused &__play-button {
        opacity: .5;

        &:hover,
        &:focus:focus-visible {
            opacity: 1;
            transform: scale(1.125);
        }
    }

    &--hide-captions &__element::cue {
        opacity: 0;
    }

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;

        z-index: 10;
    }

    &__play-button {
        @extend %flex-align-center;
        @include circle(3rem);

        padding: 0;
        transition: transform .3s ease, opacity .3s ease;
        opacity: 0;

        @include for-size(tablet-portrait-up) {
            @include circle(4rem);
        }
    }

    &__icon {
        height: 1rem;
        max-width: initial;
        max-height: initial;

        @include for-size(tablet-portrait-up) {
            height: 1.375rem;
        }
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        gap: .125rem;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        z-index: 10;

        @include for-size(tablet-portrait-up) {
            inset-block-start: unset;
            inset-block-end: 6rem;
            inset-inline-end: 0;
        }
    }

    &__control-button {
        @include square(2rem);

        padding: 0;

        @include for-size(tablet-portrait-up) {
            @include square(2.5rem);
        }
    }

    &__control-icon {
        height: 1rem;
    }

    &__element {
        width: 100%;
        height: 100%;
        max-height: 90vh;
        object-fit: contain;
        border-radius: var(--border-radius);
        background-color: var(--c-blue-dark);

        &::cue {
            @extend %cue;
        }
    }
}
