.page {
    position: relative;
    min-height: 100vh;

    &__child-wrapper {
        position: relative;
        z-index: 20;
    }

    &__grid-lines {
        z-index: 10;
    }
}
