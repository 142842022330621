@import "src/styles";

.input {
    @extend %input;

    &--has-error {
        border-block-end-color: var(--c-negative);

        @include is-dark-child {
            border-block-end-color: var(--c-negative);
        }
    }

    &::placeholder {
        color: var(--c-grey);
    }
}
