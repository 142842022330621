@import "src/styles";

.content-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-block: 4rem;

    @include is-dark-child {
        color: var(--c-white);
    }

    &__title {
        font-size: 2rem;

        h1, h2, h3, h4, h5 {
            font-size: 3rem;
        }
    }

    &__intro,
    &__paragraph {
        @extend %html-format;
    }

    &__intro p {
        @extend %highlighted-paragraph;
    }

    &__list-item + &__list-item {
        margin-block-start: .25rem;
    }
}
