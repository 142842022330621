@import "src/styles";

.image-section {

    &__inner-wrapper {
        @extend %flex-align-center;

        border: 1px solid var(--c-line);
        border-inline-end: none;
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background-color: var(--c-black);
        }
    }

    &__picture {
        border-inline-end: 0;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }
}
