@import "src/styles";

.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .125rem;

    &__nav-link {
        text-decoration: none;
        color: var(--c-grey);

        @include is-dark-child {
            color: var(--c-grey-light);
        }

        &--is-active {
            font-weight: var(--fw-bold);
            color: var(--c-black);

            @include is-dark-child {
                color: var(--c-white);
            }
        }
    }

    &__separator {
        @include icon-size(.75rem);

        margin-inline: .5rem;
    }
}
