@import "src/styles";

.download-card {
    padding-block-start: 2.5rem;

    &__title {
        @extend %heading-4;

        color: var(--c-white);
    }

    &__button-wrapper {
        margin-block-start: 1.5rem;
    }

    &__button {
        margin-block-start: .5rem;
        align-items: center;

        @include for-size(phone-only) {
            align-items: unset;
        }
    }

    &__button-label {

        &--is-small {
            font-size: 1rem;
            font-weight: var(--fw-bold);
        }
    }
}
