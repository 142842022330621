@import "src/styles";

.labeled-download-button {
    display: flex;
    gap: 1.5rem;
    line-height: 2;

    @include for-size(phone-only) {
        align-items: flex-start;
        flex-direction: column;
        gap: 1rem;
        line-height: 1.75;
    }

    &__button {
        width: fit-content;
        min-width: 5.5rem;
    }

    &__label {
        line-height: inherit;
        word-break: break-word;
    }
}
