$component: "toast-list";

.#{$component} {
    position: fixed;
    z-index: 10;
    margin: 0;
    padding: 0;
    pointer-events: none;

    &--inset-block-start {
        inset-block-start: 0;
    }

    &--inset-block-end {
        inset-block-end: 0;
    }

    &--inset-inline-start {
        inset-inline-start: 0;
    }

    &--inset-inline-end {
        inset-inline-end: 0;

        .#{$component}__item {
            justify-content: flex-end;
        }
    }

    &__item {
        display: flex;
        list-style-type: none;

        & + & {
            margin-block-start: 1rem;
        }
    }

    &__toast {
        position: relative;
        pointer-events: all;
    }
}
