$component: "section";
$wrapperComponent: "section-wrapper";

.#{$component} {

    &--different-prev-neighbour .#{$wrapperComponent} {
        padding-block-start: calc(var(--section-wrapper-padding) * 2);
    }

    &--different-next-neighbour .#{$wrapperComponent} {
        padding-block-end: calc(var(--section-wrapper-padding) * 2);
    }

    &:last-child .#{$wrapperComponent} {
        padding-block-end: calc(var(--section-wrapper-padding) * 2);
    }
}
