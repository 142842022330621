@import "src/styles";

.newsletter-form-section {
    color: var(--c-blue);

    &__wrapper {

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: calc(100% / 1.5) calc(100% / 3);
        }
    }

    &__content-wrapper {

        @include for-size(tablet-landscape-up) {
            display: flex;
            padding-block-start: 4rem;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        border-block: 1px solid var(--c-line);
        border-inline: 1px solid var(--c-line);
        padding-block: 2rem;
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
            padding-inline-end: 4rem;
        }
    }

    &__form {
        display: flex;
    }
}