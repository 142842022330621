@import "src/styles";

$component: visual-header;
$max-mobile-height: 43.75rem;
$max-tablet-height: 52.5rem;

.#{$component} {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: var(--c-white);
    background-color: var(--c-blue-dark);
    overflow: hidden;

    &::after {
        @extend %click-extend;

        content: "";
        display: block;
        z-index: 10;
        background: linear-gradient(
            180deg,
            rgba(var(--c-blue-dark-rgb), 0.90) 0%,
            rgba(var(--c-blue-dark-rgb), 0.00) 30%,
            rgba(var(--c-blue-dark-rgb), 0.30) 60%,
            rgba(var(--c-blue-dark-rgb), 0.85) 90%,
        );
    }

    &--has-quick-links &__wrapper {
        padding-block-start: calc(var(--menu-height) + 1.5rem);

        @include for-size(tablet-portrait-up) {
            padding-block-start: 8rem;
            padding-block-end: 2rem;
        }
    }

    &--is-full-screen &__wrapper {
        min-height: 100vh;
        min-height: 100svh;

        @media (min-height: #{$max-mobile-height}) {
            min-height: #{$max-mobile-height};
        }

        @include for-size(tablet-portrait-up) {
            padding-block: 8rem 4rem;

            @media (min-height: #{$max-mobile-height}) {
                min-height: 100vh;
            }

            @media (min-height: #{$max-tablet-height}) {
                min-height: #{$max-tablet-height};
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 40;
        padding-block: calc(var(--menu-height) + 2rem) 6rem;

        @include for-size(tablet-portrait-up) {
            min-height: 40rem;
            padding-block: 4rem;
        }
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }

    &__title {

        h1, h2, h3, h4 {
            text-shadow: var(--title-shadow);
            line-height: 1;
        }

        @include for-size(tablet-portrait-up) {
            max-width: 75%;
        }

        @include for-size(tablet-landscape-up) {
            max-width: 50%;
        }
    }

    &__breadcrumbs {
        order: -2;
        margin-block-end: 2rem;
    }

    &__caption {
        @extend %label;

        order: -1;
        margin-block-end: 1rem;
    }

    &__location,
    &__date {
        @extend %sub-text;

        margin-block-start: 1rem;
        color: var(--c-grey-light);
    }

    &__link-button {
        margin-block-start: 3rem;
    }

    &__default-quick-links {
        margin-block: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block: 4rem;
        }
    }

    &__home-quick-links {
        width: 100%;
        z-index: 40;
        margin-block-start: 2.5rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
        }

        @include for-size(tablet-landscape-up) {
            width: calc(100% - 12.5rem);
            padding-inline-start: 12.5rem;
        }
    }

    &__carousel {
        @extend %click-extend;

        z-index: 10;
        pointer-events: none;
    }

    &__scroll-down-indicator {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 40;
        }

        @include for-size(desktop-up) {
            transform: translateX(-100%);
        }
    }
}
