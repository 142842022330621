@import "src/styles";

.share-buyback {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {
        padding-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 2fr 1fr;
        }
    }

    &__description,
    &__aside {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__description {
        padding-block-end: 2rem;

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 2rem;
        }

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 4rem;
        }
    }

    &__title {
        @extend %heading-2;
    }

    &__intro {
        @extend %highlighted-paragraph;
    }

    &__latest-wrapper {
        flex-grow: 1;
    }

    &__sub-title {
        @extend %heading-3;
    }

    &__buyback-card {
        margin-block-start: 2rem;
    }

    &__link-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 0;
        padding: 0;
    }
}
