@import "src/styles";

$component: 'testimonial';

.#{$component} {

    &--has-portrait {

        @include for-size(tablet-portrait-up) {

            .#{$component}__wrapper {
                display: grid;
                grid-template-columns: calc(100% / 3) calc(100% / 1.5);
            }

            .#{$component}__quote {
                padding-inline: unset;
                padding-inline-end: 1.5rem;
            }

            .#{$component}__quote-text-wrapper {
                min-height: 17rem;
            }
        }
    }

    &__quote {

        @include for-size(tablet-portrait-up) {
            padding-inline: calc(100% / 6);
        }
    }

    &__portrait-wrapper {

        @include for-size(tablet-portrait-up) {
            position: relative;
            flex-grow: 1;
        }
    }

    &__portrait {

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            width: calc(100% - 1.5rem);
            height: 100%;
            background-color: transparent;
        }
    }

    &__portrait-image {
        object-fit: cover;
    }
}
