.section-wrapper {
    padding-block: var(--section-wrapper-padding);

    &[data-no-spacing-top="true"] {
        padding-block-start: 0 !important;
    }

    &[data-no-spacing-bottom="true"] {
        padding-block-end: 0 !important;
    }
}
