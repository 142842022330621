@import "src/styles";

.contact-company-form {
    color: var(--c-blue);

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-1;

        color: currentColor;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: unset;
        }
    }

    &__contact-company {

        @include for-size(tablet-portrait-up) {
            margin-inline-end: 2rem;
        }
    }
}
