@import 'src/styles';

.interactive-map-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__title {
        padding-block-start: 2rem;
        color: var(--c-blue);

        @include is-dark-child {
            color: var(--c-white);
        }

        @include for-size(tablet-landscape-up) {
            width: 25rem;
        }

        h1 {
            @extend %heading-1-unique;
        }
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem 0;
        background-color: var(--c-white);

        @include is-dark-child {
            background-color: var(--c-blue-dark);
        }
    }
}
