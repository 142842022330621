@import "src/styles";

.visual-carousel {

    @at-root {

        .header-end + & {
            margin-block-start: -4rem;
            background-color: transparent;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__slides-wrapper {
        display: flex;
        width: 100%;
        height: 100vh;
        height: 100dvh;
        overflow-x: scroll;
        overflow-y: hidden;

        @include for-size(tablet-portrait-up) {
            height: unset;
            max-height: 100vh;
            max-height: 100dvh;
            overflow: hidden;
        }
    }

    &__slides {
        display: flex;
        width: fit-content;
        translate: calc(var(--history-carousel-active-index) * -100%) 0;
        transition: translate .75s var(--strong-out-ease);
    }

    &__navigation {
        display: flex;
        justify-content: center;
        position: absolute;
        inset-block-end: calc(2rem + var(--section-wrapper-padding));
        inset-inline-start: 0;
        width: 100%;
        padding-inline: 2rem;
        z-index: 20;

        @include for-size(tablet-landscape-up) {
            inset-block-end: calc(4rem + var(--section-wrapper-padding));
        }

        &--no-spacing-bottom {
            inset-block-end: 2rem;

            @include for-size(tablet-landscape-up) {
                inset-block-end: 4rem;
            }
        }
    }
}
