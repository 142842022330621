@import "src/styles";

.floating-image-header {

    &__wrapper {

        @include for-size(tablet-portrait-up) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__picture {
        width: 100%;
        aspect-ratio: 1;

        @include for-size(tablet-portrait-up) {
            width: 50%;
            min-width: 25rem;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__content {
        padding-block: 3rem 2rem;
        padding-inline: 2rem;

        @include for-size(tablet-portrait-up) {
            max-width: 24.25rem;
            padding-block-end: 4rem;
        }
    }

    &__link-button {
        margin-block-start: 2rem;
    }
}
