@import "src/styles";

.map-cta {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {
        padding-block: 2rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: center;
        }
    }

    &__text-wrapper {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 3);
            padding-block: 4rem 5.5rem;
        }
    }

    &__title {
        @extend %heading-2;
    }

    &__intro {
        margin-block-start: 2rem;
    }

    &__cta-link {
        @extend %underscore-button;

        margin-block-start: 3rem;
    }

    &__picture-wrapper {
        @extend %flex-align-center;

        position: relative;
        width: 100%;
        aspect-ratio: 1.56;
        margin-block-start: 2rem;
        padding-inline: var(--wrapper-padding);

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 1.5);
            margin-block-start: unset;
            padding-inline: 0;
        }
    }

    &__picture {
        background-color: transparent;

        @include is-dark-child {
            background-color: transparent;
        }
    }

    &__picture,
    &__picture-overlay {
        @extend %click-extend;
    }

    &__picture-overlay-circle {
        position: absolute;
    }

    &__picture-overlay-circle:nth-child(1),
    &__picture-overlay-circle:nth-child(2) {
        --size: 7.5rem;

        @include for-size(tablet-portrait-up) {
            --size: 10rem;
        }

        @include for-size(tablet-landscape-up) {
            --size: 15rem;
        }
    }

    &__picture-overlay-circle:nth-child(3),
    &__picture-overlay-circle:nth-child(4) {
        --size: 5rem;

        @include for-size(tablet-portrait-up) {
            --size: 7.5rem;
        }

        @include for-size(tablet-landscape-up) {
            --size: 10rem;
        }
    }

    &__picture-overlay-circle:nth-child(1) {
        top: 48%;
        left: 20%;
    }

    &__picture-overlay-circle:nth-child(2) {
        top: 41%;
        left: 49%;
    }

    &__picture-overlay-circle:nth-child(3) {
        top: 41%;
        left: 75%;
    }

    &__picture-overlay-circle:nth-child(4) {
        top: 67%;
        left: 28%;
    }
}
