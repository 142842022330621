@import "src/styles";

$component: "content-columns";

.#{$component} {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title-paragraph-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        border: var(--grid-line);
        border-inline-end: 0;
        padding-block: 2rem;
        background: var(--grid-background);
    }

    &__title-paragraph-wrapper + &__columns {
        margin-block-start: -1px;
    }

    &__title {
        @extend %heading-1;

        h1, h2, h3, h4, h5 {
            font-size: 3rem;
        }
    }

    &__title-paragraph-wrapper + &__columns {

        .#{$component}__column {
            border-block-start: unset;
        }
    }

    &__columns {

        &--col-2 {
            @include three-columns-line-grid(#{$component}__column);

            .#{$component}__column {
                padding-inline-end: 4rem;
            }
        }

        &--col-3 {
            @include two-columns-line-grid(#{$component}__column);

            .#{$component}__column {
                padding-inline-end: 4rem;
            }
        }

        &--col-4,
        &--col-6 {
            @include one-column-line-grid(#{$component}__column);
        }

        &--col-4 {

            .#{$component}__column {

                @include for-size(tablet-landscape-up) {
                    border-inline-end: none;
                    width: calc(100% / 1.5);
                }
            }
        }
    }
}
