@import "src/styles";

$component: person-portrait;

.#{$component} {
    width: 100%;
    height: 100%;
    padding-block-end: 2rem;
    color: var(--c-blue);

    @include is-dark-child {
        color: var(--c-white);

        .#{$component}__job-title {
            color: var(--c-primary);
        }
    }

    &__picture {
        width: 100%;
        aspect-ratio: 1;
        margin-block-end: 2rem;
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline-end: 1rem;
    }

    &__job-title {
        order: -1;
        color: var(--c-grey);
    }

    &__title {
        @extend %heading-4;

        margin-block-start: .5rem;
        color: currentColor;
    }

    &__description {
        margin-block-start: 2rem;
        font-size: 1rem;
        color: currentColor;

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 2rem;
        }
    }
}
