@import "src/styles";

.download-list-section {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-3;

        margin-block-end: 2rem;
    }
}
