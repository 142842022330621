@import "src/styles";

$component: "details";

.#{$component} {

    &:hover,
    &:focus,
    &[open] {

        // This styling is necessary or else you won't be able to nest a detail inside a detail
        & > .#{$component}__summary .#{$component}__summary-text,
        & > .#{$component}__summary .#{$component}__summary-icon {
            color: var(--c-primary);
        }

    }

    &[open] {
        padding-block-end: 2rem;

        & > .#{$component}__summary .#{$component}__summary-icon {
            transform: rotate(180deg);
        }
    }

    &:not([open]) + & {

        .#{$component}__summary {
            border-block-start: unset;
        }
    }

    &__summary {
        @extend %heading-4;

        display: flex;
        align-items: center;
        position: relative;
        height: 4rem;
        border: 1px solid var(--c-line);
        border-inline-end: 0;
        padding-inline-end: 2rem;
        list-style: none;
        cursor: pointer;
        color: var(--c-blue-dark);
        background-color: var(--c-white);

        &::-webkit-details-marker {
            display: none;
        }

        @include is-dark-child {
            color: var(--c-white);
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 4rem;
        }
    }

    &__summary-icon {
        @include icon-size(1.5rem);

        position: absolute;
        inset-block-end: calc(50% - .75rem);
        inset-inline-end: 2rem;
    }
}
