@import "src/styles";

.page-section-skeletons {

    &__header {
        background-color: var(--c-blue-dark);
    }

    &__header-wrapper {
        padding-block-start: 12.5rem;
        padding-block-end: 6.25rem;

        @include for-size(tablet-portrait-up) {
            padding-block-start: 16rem;
            padding-block-end: 8rem;
        }
    }

    &__header-caption {
        max-width: 5rem;
        font-size: 1rem;
    }

    &__header-title-wrapper {
        width: 100%;
        max-width: 42rem;
        margin-block-start: 1rem;
    }

    &__header-title {
        font-size: 2rem;

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__body {
        padding-block: 2.5rem;
    }

    &__section {

        & + & {
            margin-block-start: 5rem;
        }
    }

    &__paragraph {
        margin-block-start: 2.5rem;

        &:first-child {
            margin-block-start: 0;
        }

        & + & {
            margin-block-start: 1.5rem;
        }
    }

    &__paragraph-line {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__text-image {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        &:nth-of-type(odd) {
            flex-direction: column;

            @include for-size(tablet-portrait-up) {
                flex-direction: row-reverse;
            }
        }
    }

    &__text-image-text-wrapper,
    &__text-image-image {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }

    &__text-image-text-wrapper {
        padding: 2.5rem;
        border: 1px solid var(--c-line);
        background-color: var(--c-white);
    }

    &__text-image-title {
        font-size: 1.5rem;
    }

    &__text-image-image {
        aspect-ratio: 16 / 9;
        background-color: var(--c-grey-light);
    }

    &__cta-card-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__cta-card {
        padding: 2.5rem;
        background-color: var(--c-white);
        border: 1px solid var(--c-line);

        &:nth-child(odd) {
            border-inline-end: none;
        }

        @include for-size(tablet-portrait-up) {
            border-inline-end: none;
        }
    }

    &__cta-card-title {
        font-size: 1.25rem;
    }

    &__cta-card-link {
        margin-block-start: 2rem;
    }

    &__text-columns-title-wrapper {
        width: 100%;
        max-width: 42rem;
    }

    &__text-columns-title {
        font-size: 1.5rem;
    }

    &__text-columns-column {
        width: 100%;
        margin-block-start: 2.5rem;
        padding-block: 2.5rem;
        border: 1px solid var(--c-line);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            width: calc(100% / 1.5);
            border-inline-end: none;
        }
    }
}
