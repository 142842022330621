@import "src/styles";

.teller-share-price {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {
        border: 1px solid var(--c-line);
        border-inline-end: 0;
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: calc(100% / 1.5) calc(100% / 3);
        }
    }

    &__content-wrapper {
        padding-top: 2rem;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }

        @include for-size(tablet-landscape-up) {
            padding: 2rem 4rem 2rem 0;
            border-inline-end: 1px solid var(--c-line);
        }
    }

    &__title {
        @extend %heading-2;
    }

    &__paragraph {
        padding-block-start: 2rem;
        padding-inline-end: 2rem;

        @include for-size(tablet-portrait-up) {
            padding-block-end: 2rem;
        }
    }

    &__figure {
        align-self: center;
        margin-block-start: 2rem;
        padding-bottom: 2rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: center;
            margin-block-start: unset;
            padding-bottom: unset;
            height: 100%;
        }

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    &__skeleton-wrapper {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;
        max-width: 12rem;
    }

    &__skeleton-amount {
        font-size: 1.75rem;
    }

    &__skeleton-label {
        font-size: 1rem;
    }
}
