@import "helpers";

:root {
    --c-primary-rgb:        204, 204, 0;
    --c-primary:            rgb(var(--c-primary-rgb));

    --c-blue-dark-rgb:      26, 38, 56;
    --c-blue-dark:          rgb(var(--c-blue-dark-rgb));

    --c-blue-medium-rgb:    24, 49, 71;
    --c-blue-medium:        rgb(var(--c-blue-medium-rgb));

    --c-blue-light-rgb:     50, 108, 219;
    --c-blue-light:         rgb(var(--c-blue-light-rgb));

    --c-blue-sky-rgb:       33, 188, 255;
    --c-blue-sky:           rgb(var(--c-blue-sky-rgb));

    --c-blue-rgb:           30, 68, 102;
    --c-blue:               rgb(var(--c-blue-rgb));

    --c-green-rgb:          0, 204, 179;
    --c-green:              rgb(var(--c-green-rgb));

    --c-black-rgb:          13, 12, 32;
    --c-black:              rgb(var(--c-black-rgb));

    --c-grey-rgb:           159, 162, 161;
    --c-grey:               rgb(var(--c-grey-rgb));

    --c-grey-light-rgb:     221, 221, 221;
    --c-grey-light:         rgb(var(--c-grey-light-rgb));

    --c-white-rgb:          255, 255, 255;
    --c-white:              rgb(var(--c-white-rgb));

    --c-broken-white-rgb:   253, 251, 251;
    --c-broken-white:       rgb(var(--c-broken-white-rgb));

    --c-positive-rgb:       var(--c-primary-rgb);
    --c-positive:           rgb(var(--c-positive-rgb));

    --c-negative-rgb:       255, 84, 84;
    --c-negative:           rgb(var(--c-negative-rgb));

    --c-warning-rgb:        255, 155, 0;
    --c-warning:            rgb(var(--c-warning-rgb));

    --c-line-rgb:           245, 240, 240;
    --c-line:               rgb(var(--c-line-rgb));

    --c-line-dark-rgb:      24, 49, 71;
    --c-line-dark:          rgb(var(--c-line-dark-rgb));

    --c-accent:             var(--c-primary);

    --f-primary:    "Nimbus Sans L", system-ui, sans-serif;

    --fw-regular:   400;
    --fw-bold:      700;

    --menu-height:              5rem;
    --grid-columns:             2;
    --wrapper-width:            min(100%, 75rem);
    --wrapper-padding:          2rem;
    --section-wrapper-padding:  2.5rem;

    --border-radius:    0;
    --box-shadow:       none;
    --title-shadow:     .5rem .5rem 2rem rgba(var(--c-black-rgb), .5);

    --strong-out-ease:  cubic-bezier(0, 0, 0, 1);

    @include for-size(tablet-portrait-up) {
        --grid-columns:     6;
        --menu-height:      6rem;
    }
}

::backdrop {
    --c-full-black-rgb:     0, 0, 0;
    --c-full-black:         rgb(var(--c-full-black-rgb));
}
