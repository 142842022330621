@import "src/styles";

.carousel {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center;

    @include for-size(tablet-portrait-up) {
        width: 100%;
        overflow: hidden;
    }

    &__list-wrapper {

        @include for-size(tablet-portrait-up) {
            translate: calc(var(--carousel-active-index) * negative(var(--carousel-slide-width))) 0;
            transition: translate .75s var(--strong-out-ease);
        }
    }

    &__list {
        display: flex;
        align-items: stretch;
        width: fit-content;
    }

    &__list-item {
        min-width: var(--carousel-slide-width);
        scroll-snap-align: center;

        @include for-size(tablet-portrait-up) {
            scroll-snap-align: unset;
        }
    }
}
