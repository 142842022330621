@import "src/styles";

.generic-table-section {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__wrapper {
        padding-block-start: 2rem;
    }

    &__title {
        @extend %heading-2;
    }

    &__paragraph,
    &__table {
        margin-block-start: 2rem;
    }
}
