@import "src/styles";

.generic-table {

    @include for-size(laptop-up) {
        overflow-x: hidden;
    }

    &__header-row:nth-child(1) {
        border-block-start: unset;
        background-color: var(--c-white);

        @include is-dark-child {
            background-color: var(--c-blue-dark);
        }
    }
}
