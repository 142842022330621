@import "src/styles";

$component: cta-card-list;

.#{$component} {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-3;

        margin-block-end: 2rem;
    }

    &__list {
        @include three-columns-line-grid(#{$component}__list-item);
    }

    &__card {
        height: 100%;
    }
}
