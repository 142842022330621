.logo {
    min-height: 2.5rem;

    &__white {
        fill: var(--c-white);
    }

    &__black {
        fill: var(--c-black);
    }

    &__background {
        fill: var(--c-primary);
    }
}
