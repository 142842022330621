@import "src/styles";

$component: "menu";

.#{$component} {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 100;
    width: 100%;
    height: var(--menu-height);
    pointer-events: none;

    &--show-search {

        .#{$component}__search {
            z-index: 20;
        }

        .#{$component}__hamburger-wrapper {
            width: 0;
        }

        .#{$component}__hamburger-button {
            opacity: 0;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 20;
        height: 100%;
        padding-block: 1.5rem;

        @include for-size(tablet-portrait-up) {
            padding-block: 2rem;
        }
    }

    &__home-link {
        z-index: 40;
        height: var(--menu-height);
        margin-inline-end: auto;
        pointer-events: all;
    }

    &__logo {
        height: 100%;
        color: var(--c-primary);
    }

    &__search-sizer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
    }

    &__controls-wrapper {
        display: flex;
        padding-block: 1rem;
        padding-inline: 1.5rem;
        height: var(--menu-height);
        color: var(--c-white);
        pointer-events: all;
        background-color: var(--c-blue);

        @include for-size(tablet-portrait-up) {
            padding-block: 1.5rem;
        }
    }

    &__hamburger-wrapper {
        position: relative;
        z-index: 10;
        width: 2.5rem;
        overflow: clip;
        transition: width var(--menu-animation-duration) ease;
    }

    &__hamburger-button {
        padding-block: 1rem;
        padding-inline: 1rem 0;
        color: currentColor;
        background-color: transparent;
        transition: opacity var(--menu-animation-duration) ease;
        opacity: 1;

        &:hover,
        &:focus {
            color: var(--c-accent);
        }
    }

    &__hamburger-icon {
        width: 1.5rem;
    }

    &__navigation {
        z-index: 30;
    }
}
