@import "src/styles";

.article-card-list-skeletons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    column-gap: 1px;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 2rem;
        }
    }

    &__card-header {
        min-height: 3rem;
    }

    &__card-body {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--c-white);
        background-color: var(--c-grey);
    }

    &__card-meta {
        width: 50%;
        padding-block: 1rem;
        padding-inline: 2rem;
        background-color: inherit;
        translate: 0 -50%;
    }

    &__card-date {
        margin-block-start: 1rem;
    }

    &__card-text-wrapper {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        padding-block-start: 0;
    }

    &__card-title {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__card-read-more {
        width: 8rem;
        margin-block-start: 2.5rem;
    }
}
