@import "src/styles";

$component: gallery;

.#{$component} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-gap: 1rem;
    grid-template-areas:
        "a a"
        "a a"
        "b b"
        "c c";

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "a a b b"
            "a a c c";
        aspect-ratio: 1.85;
    }

    &--is-quartet-type {
        grid-template-areas:
            "a b"
            "c c"
            "d d"
            "d d";

        @include for-size(tablet-portrait-up) {
            grid-template-areas:
                "a b d d"
                "c c d d";
        }
    }

    &__picture {
        width: 100%;

        &:nth-child(1) {
            grid-area: a;
        }

        &:nth-child(2) {
            grid-area: b;
        }

        &:nth-child(3) {
            grid-area: c;
        }

        &:nth-child(4) {
            grid-area: d;
        }
    }

   &__image {
       object-fit: cover;
   }
}
