@import "../helpers";
@import "button";
@import "card";
@import "html";
@import "input";
@import "nav-item";
@import "print";
@import "typography";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%click-extend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

%draggable {
    background-color: var(--c-white);
    border: 1px solid currentColor;
}

%cue {
    font-family: var(--f-primary);
    font-size: .75rem;
    font-weight: var(--fw-bold);
    text-shadow: 1px 1px 1px var(--c-black);
    background-color: transparent;

    @include for-size(tablet-portrait-up) {
        font-size: 1.25rem;
    }
}

%skeleton-fade {
    position: relative;
    overflow: hidden;

    &::after {
        @extend %click-extend;

        content: "";
        background: linear-gradient(
            90deg,
            transparent 0%,
            rgba(var(--c-white-rgb), .25) 50%,
            transparent 100%
        );
        animation: skeleton-load 2s ease infinite;

        @include rtl {
            scale: -1 1;
            animation-direction: reverse;
        }
    }
}

%text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
