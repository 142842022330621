@import "src/styles";

.year-selector-button {
    position: relative;
    padding: 0;
    line-height: 1.125;
    font-size: 2rem;
    font-weight: var(--fw-regular);
    background: none;

    @include is-dark-child {
        color: var(--c-white);
    }

    &::before {
        content: unset;
    }

    &--is-active,
    &:hover,
    &:focus {
        color: var(--c-accent);

        @include is-dark-child {
            color: var(--c-accent);
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            inset-inline-start: 0;
            inset-block-end: 0;
            width: 100%;
            height: 1px;
            background: var(--c-accent);
        }
    }
}
