@import "src/styles";

$component: latest-articles;

.#{$component} {
    position: relative;
    z-index: 20;

    @include is-dark-parent {
        color: var(--c-white);
    }

    &:last-child {
        margin-block-start: -4rem;
        translate: 0 calc((var(--section-wrapper-padding) * 2) + 4rem);
        background-color: transparent;

        .#{$component}__wrapper::before {
            height: calc(100% - (calc(var(--section-wrapper-padding) * 2) + 4rem));
        }
    }

    &__title {
        @extend %heading-3;
    }

    &__list {
        margin-block-start: 4rem;
    }
}
