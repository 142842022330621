@import "src/styles";

$component: committee;

.#{$component} {
    display: grid;

    @include is-dark-parent {
        color: var(--c-white);
    }

    &--has-1-member,
    &--has-2-members {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                display: grid;
                grid-template-columns: calc(100% / 1.5) calc(100% / 3);

                .#{$component}__members {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }

        &.#{$component}--hide-portraits .#{$component}__person-wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-start: calc(6rem + var(--committee-title-height));
            }
        }
    }

    &--has-3-members {

        .#{$component}__wrapper {

            @include for-size(tablet-landscape-up) {
                display: grid;
                grid-template-columns: 50% 50%;

                .#{$component}__members {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        &.#{$component}--hide-portraits .#{$component}__person-wrapper {

            @include for-size(tablet-landscape-up) {
                padding-block-start: calc(6rem + var(--committee-title-height));
            }
        }
    }

    &--has-4-or-more-members {

        .#{$component}__content-wrapper {

            @include for-size(tablet-landscape-up) {
                max-width: calc(100% / 1.5);
            }
        }

        &.#{$component}--hide-portraits .#{$component}__person-wrapper {

            @include for-size(tablet-landscape-up) {
                padding-block-start: calc(6rem + var(--committee-title-height));
            }
        }
    }

    &--hide-portraits &__person-wrapper {
        align-items: flex-start;
    }

    &__content-wrapper {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 4rem;
        }
    }

    &__person {
        height: unset;
        padding-block-end: 0;
    }
}
