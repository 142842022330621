@import "src/styles";

.select {
    width: 100%;
    border: 1px solid rgba(var(--c-blue-rgb), .8);
    font-weight: var(--fw-regular);
    color: var(--c-blue);
    cursor: pointer;

    @include is-dark-child {
        border-color: rgba(var(--c-white-rgb), .8);
        color: rgba(var(--c-white-rgb), .8);
    }

    &__label {
        margin-block-end: .5rem;
    }

    &--is-disabled {
        cursor: not-allowed !important;
        opacity: .5;
    }

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &__control {
        border: none !important;
        border-radius: var(--border-radius) !important;
        padding-inline: 1.25rem !important;
        background-color: transparent !important;
        box-shadow: var(--box-shadow) !important;
        color: currentColor !important;

        &--is-focused {
            border: none !important;
            box-shadow: none;
        }
    }

    &__input {
        padding-block: .75rem;
        padding-inline: 0;
        color: currentColor !important;
    }

    &__placeholder {
        color: currentColor !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__indicator {
        color: currentColor !important;
    }

    &__icon {
        @include icon-size(.75rem);

        color: inherit;
    }

    &__value-container {
        padding-block: .5rem !important;
        padding-inline: 0 .75rem !important;
    }

    &__single-value {
        color: currentColor !important;
    }

    &__menu {
        border: 1px solid currentColor !important;
        border-radius: 0 !important;
        background-color: var(--c-white) !important;
        box-shadow: var(--box-shadow) !important;
        color: currentColor !important;

        @include is-dark-child {
            background-color: var(--c-blue-dark) !important;
        }
    }

    &__menu-list {
        max-height: 14rem !important;
        padding: 0 !important;
    }

    &__option {
        color: inherit !important;

        &--is-focused {
            color: var(--c-white) !important;
            background-color: rgba(var(--c-black-rgb), .3) !important;

            @include is-dark-child {
                color: var(--c-white) !important;
                background-color: rgba(var(--c-white-rgb), .3) !important;
            }
        }

        &--is-selected {
            color: var(--c-white) !important;
            background-color: var(--c-blue) !important;

            @include is-dark-child {
                color: var(--c-black) !important;
                background-color: var(--c-white) !important;
            }
        }
    }
}
