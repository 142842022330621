@import "src/styles";

.download-button {
    display: flex;
    align-items: baseline;
    gap: .5rem;
    height: 2.375rem;
    border: 1px solid currentColor;
    padding-block: .5rem;
    padding-inline: .875rem 1rem;
    font-weight: var(--fw-bold);
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--c-blue-dark);
    line-height: 1.4;

    &:hover,
    &:focus {
        color: var(--c-primary);
    }

    @include is-dark-child {
        border-color: var(--c-white);
        color: var(--c-white);

        &:hover,
        &:focus {
            border-color: var(--c-primary);
            color: var(--c-primary);
        }
    }

    &__icon {
        @include icon-size(.875rem);
    }
}
