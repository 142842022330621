@import "src/styles";

$component: "flip-card";

.#{$component} {
    --flip-card-padding: 2rem;

    position: relative;
    z-index: 10;
    width: 100%;
    height: calc(var(--flip-card-height) + (var(--flip-card-padding) * 2));
    padding: 0;
    background-color: transparent;
    perspective: 60rem;

    &--prefers-reduced-motion {
        animation: none;

        .#{$component}__flip-wrapper {
            transition-duration: 1ms;
        }
    }

    &--is-flipped {
        z-index: 30;

        .#{$component}__flip-wrapper {
            transform: rotateY(180deg);
        }

        .#{$component}__front-wrapper,
        .#{$component}__back-wrapper {
            pointer-events: none;
        }
    }

    &__flip-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform .5s ease;
        transform-style: preserve-3d;
        will-change: transform;
    }

    &__front-wrapper,
    &__back-wrapper {
        @extend %click-extend;

        padding: var(--flip-card-padding);
        backface-visibility: hidden;
    }

    &__front-wrapper {
        color: var(--c-white);
        background-color: var(--c-blue);
    }

    &__back-wrapper {
        color: var(--c-black);
        transform: rotateY(180deg);
        background-color: var(--c-broken-white);
    }

    &__flip-button {
        @extend %button--no-background;
        @extend %label;

        padding: 0;
        color: currentColor;
        pointer-events: all;

        &:disabled {
            cursor: default;
            color: currentColor;
            background-color: transparent;
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__key-figure {
        margin-block-start: 2rem;
    }

    &__key-figure-label {
        color: var(--c-primary);
    }

    &__body-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-block-start: 2rem;
    }

    &__body {
        @extend %highlighted-paragraph;
    }

    &__image {
        background-color: transparent;
    }

    &__flip-icon {
        @extend %button--no-background;

        display: none;
        position: absolute;
        inset-block-start: var(--flip-card-padding);
        inset-inline-end: var(--flip-card-padding);
        padding: 0;
        font-size: .75rem;
        color: var(--c-primary);
        pointer-events: none;

        @include touch-screen {
            display: block;
        }

        &--is-back {
            color: var(--c-black);
        }
    }
}
