@import "src/styles";

.share-performance {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-2;
    }

    &__wrapper {

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 1.5);
        }
    }

    &__iframe {
        border: unset;
        width: 100%;
    }

    &__iframe-wrapper {
        min-height: 40rem;
        margin-block-start: 2rem;
        padding-block: 1rem;
        border: 1px solid var(--c-line);
        background-color: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-blue-dark);
            background-color: var(--c-blue-dark);
        }

        @include for-size(tablet-landscape-up) {
            border-inline-end: unset;
        }
    }
}
