@import "src/styles";

.flip-card-list {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-2;
    }

    &__list {
        display: grid;
        gap: 2rem;
        margin-block-start: 2rem;
        padding-inline-end: 2rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
