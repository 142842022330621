@import "src/styles";

$component: "illustrated-content-bridge";

.#{$component} {
    position: relative;
    overflow-x: clip;

    @include is-dark-parent {
        color: var(--c-white);
    }

    @include is-dark-child {
        border-color: var(--c-line-dark);
        color: var(--c-white);
        background: var(--c-blue-dark);
    }

    &--is-vision-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 85%;

            @include for-size(tablet-portrait-up) {
                width: 60%;
                translate: 7% 8rem;
            }
        }
    }

    &--is-vision-alternative-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 85%;

            @include for-size(tablet-portrait-up) {
                width: 60%;
                translate: 7% 8rem;
            }
        }
    }

    &--is-manufacturing-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 85%;

            @include for-size(tablet-portrait-up) {
                width: 60%;
                translate: 11% 6rem;
            }
        }
    }

    &--is-manufacturing-alternative-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 85%;

            @include for-size(tablet-portrait-up) {
                width: 60%;
                translate: 11% 6rem;
            }
        }
    }

    &--is-sustainability-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 75%;
            translate: 2rem -2rem;

            @include for-size(tablet-portrait-up) {
                width: 50%;
                translate: 11% 1rem;
            }
        }
    }

    &--is-people-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 85%;

            @include for-size(tablet-portrait-up) {
                width: 60%;
                translate: 11% 6rem;
            }
        }
    }

    &--is-innovation-type {

        .#{$component}__wrapper {

            @include for-size(tablet-portrait-up) {
                padding-block-end: 8rem;
            }

            @include for-size(tablet-landscape-up) {
                padding-block-end: 12rem;
            }
        }

        .#{$component}__picture {
            width: 65%;
            translate: 2rem 4rem;

            @include for-size(tablet-portrait-up) {
                width: 50%;
                translate: 11% 6rem;
            }
        }
    }

    &__wrapper {
        padding-block: 4rem var(--section-wrapper-padding);

        @include for-size(tablet-portrait-up) {
            padding-block: 8rem 4rem;
        }
    }

    &__picture-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__picture {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 0;
        overflow: visible;
        pointer-events: none;

        @include is-dark-child {
            background: none;
        }

        @include for-size(tablet-portrait-up) {
            width: 80%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    &__image {
        height: fit-content;
    }

    &__title,
    &__paragraph {
        max-width: 50rem;
    }

    &__title {

        h1, h2, h3, h4 {
            @extend %heading-1-unique;
        }
    }
}
