@import "src/styles";

.scroll-down-indicator {
    @extend %button--no-background;

    border: none;
    padding: 0;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size: .6875rem;
    letter-spacing: 1.5px;
    cursor: pointer;
    color: var(--c-white);

    &__label {
        padding-inline-end: 1.5rem;
        white-space: nowrap;
    }

    &__line {
        width: 1px;
        height: 100%;
        margin-block-start: .75rem;
        background: var(--c-primary);
        animation: scroll-down-indicator-animation 1.75s linear infinite;
        animation-timing-function: ease-out;
        transform-origin: 0 0;
    }
}
