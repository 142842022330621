@import "src/styles";

.solution-card {
    --c-read-more: currentColor;

    position: relative;

    &__image-wrapper {
        width: 100%;
        aspect-ratio: 16 / 9;
    }

    &__picture,
    &__picture-placeholder {
        width: 100%;
        height: 100%;
    }

    &__picture-placeholder {
        background-color: var(--c-grey-light);

        @include is-dark-child {
            background-color: var(--c-blue-medium);
        }
    }

    &__image {
        object-fit: cover;
    }

    &__text-wrapper {
        position: relative;
        padding-inline-end: 2rem;

        &:hover,
        &:focus-within {
            --c-read-more: var(--c-accent);
        }
    }

    &__title {
        @extend %heading-4;

        margin-block-start: 2rem;
        color: currentColor;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__read-more-label {
        @extend %underscore-button;

        margin-block-start: 1rem;
        font-size: 1rem;
        color: var(--c-read-more);
    }
}
