@import "src/styles";

.search-results {
    padding-block: 2rem 4rem;

    @include for-size(tablet-portrait-up) {
        padding-block: 4rem 8rem;
    }

    &__no-results-description {
        font-weight: var(--fw-bold);
    }

    &__no-results-list {
        margin-block-start: 5rem;
    }

    &__results {

        & + & {
            margin-block-start: 2rem;

            @include for-size(tablet-portrait-up) {
                margin-block-start: 4rem;
            }
        }
    }
}
