@import "src/styles";

$component: cta-card;

.#{$component} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-block: 2rem;
    line-height: unset;
    font-weight: var(--fw-regular);
    text-decoration: none;
    color: var(--c-blue);

    &:hover,
    &:focus-within {
        color: var(--c-blue);

        .#{$component}__title {
            color: var(--c-primary);
        }
    }

    @include is-dark-child {
        color: var(--c-white);
    }

    @include for-size(tablet-portrait-up) {
        padding-inline-end: 1rem;
    }

    &__title {
        @extend %heading-3;
    }

    &__caption {
        order: -1;
        color: red;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
        }
    }

    &__description {
        @extend %highlighted-paragraph;
        @extend %text-with-underline;

        margin-block-start: 1rem;
        line-height: 1.75;
        font-size: 1.125rem;

        &::after {
            width: 3.125rem;
        }
    }
}
