@import "src/styles";

$component: home-quick-link;

.#{$component} {
    display: block;
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
        color: inherit;

        .#{$component}__top-wrapper {
            width: 100%;
        }
    }

    @include for-size(tablet-portrait-up) {
        width: 12.5rem;
        min-height: 12.5rem;
    }

    @media (prefers-reduced-motion: reduce) {

        .#{$component}__top-wrapper {
            transition: none;
        }
    }

    .#{$component}__bottom {
        color: var(--c-blue);

        &:hover,
        &:focus {
            color: var(--c-blue);
        }

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__top-wrapper {
        @extend %click-extend;

        width: 0;
        transition: width .5s var(--strong-out-ease);
        overflow: hidden;
    }

    .#{$component}__top {
        color: var(--c-blue-dark);
        background: var(--c-accent);

        &::before {
            background-color: var(--c-blue-dark);
        }
    }
}