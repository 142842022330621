@import "src/styles";

$component: value-card-list;

.#{$component} {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__list {
        margin-block-start: 2rem;

        &--has-3-cards {
            @include three-columns-line-grid(#{$component}__list-item);
        }

        &--has-4-cards {
            @include four-columns-line-grid(#{$component}__list-item);
        }
    }

    &__title {
        @extend %heading-2;
    }
}
