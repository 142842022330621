@import "src/styles";

.article-card-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    column-gap: 1px;

    @include for-size(tablet-portrait-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }

    &__list-item {

        @include for-size(tablet-portrait-up) {
            padding-inline-end: 2rem;
        }
    }
}
