@import "src/styles";

.wrapper {
    --c-wrapper-line: var(--c-line);

    position: relative;
    width: var(--wrapper-width);
    margin-inline: auto;
    padding-inline: var(--wrapper-padding);

    & > * {
        position: relative;
        z-index: 1;
    }

    &::before {
        @extend %click-extend;

        content: '';
        display: block;
        inset-inline-start: var(--wrapper-padding);
        width: calc(100% - var(--wrapper-padding) * 2);
        border-inline-end: 1px solid var(--c-wrapper-line);
        background: repeating-linear-gradient(90deg,
            var(--c-wrapper-line), var(--c-wrapper-line) 1px,
            transparent, transparent 0,
            transparent, transparent 50%,
        );
    }

    @include is-dark-child {
        --c-wrapper-line: var(--c-line-dark);
    }

    @include for-size(tablet-portrait-up) {

        &::before {
            background: repeating-linear-gradient(90deg,
                var(--c-wrapper-line), var(--c-wrapper-line) 1px,
                transparent, transparent 0,
                transparent, transparent calc(100% / 6),
            );
        }
    }

    @include for-size(laptop-up) {
        padding-inline: 0;

        &::before {
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &--hide-grid {

        &::before {
            display: none;
        }
    }
}
