@import "src/styles";

.skeleton {
    @extend %skeleton-fade;

    --skeleton-width: 100%;

    display: block;
    width: var(--skeleton-width);
    height: 1.5em;
    color: var(--c-grey-light);
    border-radius: var(--border-radius);
    background-color: currentColor;
}
