@import "src/styles";

.sdg-carousel {
    position: relative;
    overflow: hidden;

    &__inner-wrapper {
        position: relative;
    }

    &__navigation {
        display: flex;
        justify-content: center;
        position: absolute;
        inset-block-end: 2rem;
        inset-inline-start: 0;
        width: 100%;
        padding-inline: 2rem;
        z-index: 20;

        @include for-size(tablet-portrait-up) {
            inset-block-end: 2rem;
        }
    }
}
