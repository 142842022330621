@import "src/styles";

.solution-detail-skeletons {

    &__header {
        position: relative;
        padding-block: 12.5rem 6.25rem;
        color: var(--c-white);
        background-color: var(--c-blue-dark);

        @include for-size(tablet-portrait-up) {
            padding-block: 16rem 8rem;
        }
    }

    &__header-wrapper {
        width: var(--wrapper-width);
        margin-inline: auto;
        padding-inline: var(--wrapper-padding);

        @include for-size(tablet-portrait-up) {
            display: flex;
            align-items: flex-start;
        }

        @include for-size(laptop-up) {
            padding-inline: 0;
        }
    }

    &__header-text-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        z-index: 30;

        @include for-size(tablet-portrait-up) {
            width: 50%;
        }
    }

    &__category {
        max-width: 5rem;
        font-size: 1rem;
        color: var(--c-primary);
    }

    &__title-wrapper {
        width: 100%;
        margin-block-start: 1rem;
    }

    &__title {
        font-size: 2rem;

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__header-image-wrapper {
        align-self: flex-end;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        z-index: 20;
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
            width: 50%;
        }
    }

    &__header-image {
        width: 100%;
        aspect-ratio: 1.625;
        background-color: var(--c-blue);

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-block-end: -15rem;
            inset-inline-end: 0;
            min-width: calc(400% / 3);
        }
    }

    &__body-wrapper {
        margin-block-start: 12rem;

        @include for-size(tablet-landscape-up) {
            display: grid;
            grid-template-columns: calc(100% / 1.5) calc(100% / 3);
        }
    }

    &__body {
        padding-block-end: 4rem;
    }

    &__paragraph {

        & + & {
            margin-block-start: 2.5rem;
        }
    }

    &__paragraph-line {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__sidebar {
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        gap: 2.5rem;
        margin-block-start: 2.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: 1fr;
            gap: 2.5rem;
            margin-block-start: 0;
            padding-inline-start: 4rem;
        }
    }

    &__sidebar-card-wrapper {
        padding-inline-end: 2rem;

        @include for-size(tablet-landscape-up) {
            padding-inline-end: 0;
        }
    }

    &__sidebar-card {
        width: 100%;
        padding: 2rem;
        background-color: var(--c-blue);
    }

    &__sidebar-card-title {
        color: var(--c-primary);
    }

    &__sidebar-card-description {
        margin-block-start: 5rem;
    }
}
