@import "src/styles";

.footer {
    --c-footer: var(--c-black);
    --c-footer-background: var(--c-white);
    --footer-line: 1px solid var(--c-line);

    position: relative;
    padding-block: 8rem 2rem;
    border-block-start: 1px solid var(--c-line);
    color: var(--c-footer);
    background-color: var(--c-footer-background);

    @include is-dark-parent {
        --c-footer: var(--c-white);
        --c-footer-background: var(--c-blue-dark);
        --footer-line: 1px solid var(--c-line-dark);

        border-block-start: none;
    }

    @include for-size(tablet-portrait-up) {
        padding-block: 8rem 6rem;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 20;
    }

    &__nav {
        position: relative;
        width: 100%;
        padding-block: 2.5rem 2rem;
        padding-inline-end: 3.75rem;
        border-block-start: var(--footer-line);
        border-inline-start: var(--footer-line);
        background-color: var(--c-footer-background);

        &:last-of-type {
            border-block-end: var(--footer-line);
        }

        @include for-size(tablet-portrait-up) {
            width: 50%;
            padding-block: 2.5rem;
            border-inline-end: unset;

            &:nth-of-type(n + 5) {
                border-block-end: var(--footer-line);
            }
        }

        @include for-size(tablet-landscape-up) {
            width: calc(100% / 3);

            &:nth-of-type(n + 4) {
                border-block-end: var(--footer-line);
            }
        }

        &--legal {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-block: 2rem 0;
            padding-inline-end: unset;
            font-size: .875rem;

            @include for-size(tablet-portrait-up) {
                padding-block: 3.875rem 2.5rem;
                padding-inline-end: 3.75rem;
            }
        }
    }

    &__nav-title {
        @extend %heading-4;
    }

    &__nav-list {
        margin-block-start: 1rem;
    }

    &__nav-item {
        color: currentColor;
        line-height: 2;
    }

    &__nav-link {
        color: currentColor;
        text-decoration: none;
    }

    &__copyright {
        @extend %flex-align-center;

        margin-block-start: 2rem;
        padding: 1rem;
        font-size: inherit;
        color: var(--c-grey);
        border-block-start: var(--footer-line);

        @include is-dark-child {
            color: var(--c-grey-light);
        }

        @include for-size(tablet-portrait-up) {
            display: block;
            margin-block-start: 1rem;
            padding: 0;
            border-block-start: unset;
        }
    }

    &__grid-lines {
        z-index: 10;
    }
}
