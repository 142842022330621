@import "src/styles";

.newsletter-call-to-action {
    position: relative;
    z-index: 20;

    &:last-child {
        margin-block-start: -4rem;
        translate: 0 calc((var(--section-wrapper-padding) * 2) + 4rem);
        background-color: transparent;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
        color: var(--c-white);
        background-color: var(--c-blue);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            gap: 6.25rem;
        }
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__icon {
        height: 1.125rem;
    }

    &__title {
        @extend %heading-3;
    }

    &__link-button {
        padding-inline-end: 0;
    }
}
