@import "src/styles";

$component: vacancy-card-list-skeletons;

.#{$component} {
    @include two-columns-line-grid(#{$component}__list-item);

    &__card {
        padding-block: 2rem;
        padding-inline: 0 2rem;

        @include for-size(laptop-up) {
            padding-block: 3.5rem;
        }
    }

    &__card-title {
        font-size: 2rem;
    }

    &__card-subtitle {
        margin-block-start: .5rem;
        font-size: 1rem;
    }

    &__card-description {
        margin-block-start: 2rem;
    }

    &__card-description-line {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__card-download-wrapper {
        width: 50%;
    }

    &__card-download-button {
        margin-block-start: 1.5rem;
    }
}
