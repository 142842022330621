@import "src/styles";

.modal-content {

    &__header {
        display: flex;
        align-items: center;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        min-width: 2.5rem;
        margin-inline-end: 1rem;
        color: var(--c-white);
        background-color: var(--c-grey);
    }

    &__title {
        @extend %heading-2;
    }

    &__header + &__content-wrapper {
        margin-block-start: 2rem;
    }
}
