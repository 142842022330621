@import "src/styles";

$component: "page-search-results";

.#{$component} {

    &__amount-title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__title {
        @extend %heading-2;

        margin-block-start: 1rem;
    }

    &__list {
        @include three-columns-line-grid(#{$component}__list-item);

        margin-block-start: 2rem;
    }
}
