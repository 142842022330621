@import "src/styles";

.table-head {
    font-weight: var(--fw-bold);
    color: var(--c-blue-dark);
    background-color: inherit;

    @include is-dark-child {
        color: var(--c-accent);
    }
}
