@import "src/styles";

$component: "highlighted-page";

.#{$component} {
    --title-translate-y: 2rem;
    --cover-width: 0;
    --cover-background: var(--c-white);
    --image-offset: 0;

    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;

    @include is-dark-parent {
        --cover-background: var(--c-blue-dark);
    }

    &--is-animation-disabled {

        .#{$component}__picture,
        .#{$component}__text-inner-wrapper {
            transform: none;
        }

        .#{$component}__intro {
            opacity: 1;
        }

        .#{$component}__cover-top,
        .#{$component}__cover-right-wrapper,
        .#{$component}__cover-bottom,
        .#{$component}__cover-left-wrapper {
            display: none;
        }
    }

    &__picture-wrapper {
        display: flex;
        flex-grow: 1;
        position: relative;
        min-width: calc(var(--wrapper-width) / 0.75);
    }

    &__text-wrapper {
        @extend %click-extend;

        display: flex;
    }

    &__text-inner-wrapper {
        position: relative;
        z-index: 40;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        padding: 2rem;
        color: var(--c-white);
        transform: translate(0, var(--title-translate-y));

        @include for-size(tablet-portrait-up) {
            padding: 4rem;
        }
    }

    &__title {

        h1, h2, h3, h4 {
            @extend %heading-1;

            font-size: 2rem;
            font-weight: var(--fw-bold);

            @include for-size(tablet-portrait-up) {
                font-size: 4rem;
            }
        }
    }

    &__paragraph {
        @extend %highlighted-paragraph;

        max-width: 40rem;
        font-size: 1rem;
        opacity: 0;

        @include for-size(tablet-portrait-up) {
            max-width: 50rem;
            font-size: 1.375rem;
        }
    }

    &__picture {
        @extend %click-extend;

        z-index: 20;
        transform: scale(.875);
    }

    &__image {
        object-fit: cover;
        translate: 0 var(--image-offset);
    }

    &__nav-link {
        opacity: 0;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            inset-inline-end: 2rem;
            inset-block-end: 6rem;
            z-index: 10;
        }
    }

    &__cover-top,
    &__cover-right,
    &__cover-right-wrapper,
    &__cover-bottom,
    &__cover-left,
    &__cover-left-wrapper {
        @extend %click-extend;

        z-index: 30;
        background-color: var(--cover-background);
    }

    &__cover-right-wrapper,
    &__cover-left-wrapper {
        inset-block-start: -15%;
        height: 130%;
        background: none;
    }

    &__cover-top {
        height: 5%;
    }

    &__cover-right-wrapper {
        left: unset;
        inset-inline-end: negative(calc(var(--cover-width) + 1px));
        width: var(--cover-width);
    }

    &__cover-bottom {
        top: unset;
        height: 5%;
        inset-block-end: 0;
    }

    &__cover-left-wrapper {
        inset-inline-start: negative(var(--cover-width));
        width: var(--cover-width);
    }
}
