@import "src/styles";

$component: vacancy-card-list;

.#{$component} {

    &__no-results-wrapper {
        padding-block: 2rem;
    }

    &__no-results {

        @include is-dark-child {
            color: var(--c-white);
        }
    }

    &__list {
        @include two-columns-line-grid(#{$component}__list-item);
    }
}
