@import "src/styles";

$component: navigation-group-item;

.#{$component} {
    --navigation-item-line-width: 2.5rem;

    @extend %nav-item;

    &__button {
        @extend %nav-item-button;

        &--is-active {
            color: var(--c-accent);
        }

        &--is-active,
        &:hover,
        &:focus {

            &::before {
                opacity: 1;
            }

            .#{$component}__label {
                transform: translateX(var(--navigation-item-line-width));
            }

            .#{$component}__chevron-icon:last-of-type {
                transform: translateX(.5rem);
            }
        }
    }

    &__label {
        @extend %nav-item-label;
    }

    &__chevron-icons-wrapper {
        display: inline;
        position: relative;
        margin-inline-start: .5rem;
    }

    &__chevron-icon {
        position: absolute;
        inset-block-start: .25rem;
        transition: transform .25s ease-out;
    }
}
