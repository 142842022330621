@import "src/styles";

$component: "search-results-skeletons";

.#{$component} {

    &__group {

        & + & {
            margin-block-start: 2rem;

            @include for-size(tablet-portrait-up) {
                margin-block-start: 4rem;
            }
        }
    }

    &__amount-title {
        width: 10rem;
        font-size: 1rem;
    }

    &__title {
        @extend %heading-2;

        margin-block-start: 1rem;
    }

    &__page-list {
        @include three-columns-line-grid(#{$component}__page-result);

        margin-block-start: 2rem;
    }

    &__page-result {
        display: flex;
        flex-direction: column;
        padding-block: 1rem;
    }

    &__page-title {
        margin-block-start: 1rem;
        font-size: 1.25rem;
    }

    &__page-category {
        order: -1;
        width: 6rem;
    }

    &__page-description {
        @extend %text-with-underline;

        margin-block-start: 1.5rem;
    }

    &__page-description-line {

        & + & {
            margin-block-start: .5rem;
        }
    }

    &__article-list {
        margin-block-start: 2rem;
    }

    &__article-result {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-block: 1rem;
        border-block-start: 1px solid var(--c-line);
        background-color: var(--c-white);

        &:last-child {
            border-block-end: 1px solid var(--c-line);
        }
    }

    &__article-title-wrapper {
        max-width: 36rem;
    }

    &__article-title {
        margin-block-start: 1rem;
        font-size: 1.25rem;
    }

    &__article-meta-wrapper {
        order: -1;
        width: 12rem;
    }

    &__article-category {
        font-size: 1rem;
        color: var(--c-primary);
    }

    &__article-date {
        margin-block-start: .5rem;
        font-size: 1rem;
    }

    &__article-link-button {
        margin-block-start: 1rem;
        width: 8rem;
    }
}
