%html-format {
    line-height: 2;

    & > * + * {
        margin-block-start: 2rem;
    }

    h2 {
        @extend %heading-2;
    }

    h3 {
        @extend %heading-3;
    }

    h4 {
        @extend %heading-4;
    }

    ul {
        padding-inline-start: 1.5rem;
    }

    ol {
        padding-inline-start: 1.125rem;
    }

    ol li,
    ul li {
        font-size: 1.125rem;
        list-style-type: initial;
    }

    a {
        color: var(--c-grey);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
    }

    figure {
        margin-inline: 0;
        margin-block-end: 0;
    }

    figcaption {
        @extend %label;
        caption-side: bottom;
        margin-block-start: .5rem;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid var(--c-line);
    }

    tr {
        background-color: var(--c-white);

        @include is-dark-child {
            color: var(--c-white);
            background-color: var(--c-blue);
        }

        &:nth-of-type(even) {
            background-color: var(--c-broken-white);

            @include is-dark-child {
                background-color: var(--c-blue-medium);
            }
        }
    }

    th,
    td {
        border: 1px solid var(--c-line);
        padding: .5rem;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
        border: none;
    }
}
