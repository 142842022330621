@import "src/styles";

.home-quick-links-group {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    @include for-size(phone-only) {
        grid-template-columns: repeat(1, 100%);
    }

    @include for-size(tablet-portrait-up) {
        display: flex;
        flex-wrap: wrap;
    }
}
