@import "src/styles";

.disclaimer {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__paragraph {
        font-size: 1rem;
    }
}
