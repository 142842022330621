@import "src/styles";

.webcast {

    @include is-dark-parent {
        color: var(--c-white);
        background-color: var(--c-blue-dark);
    }

    &__video {
        margin-block-start: 2rem;
    }

    &__poster-placeholder {
        width: 100%;
        background: var(--c-black);
    }

    &__iframe {
        width: 100%;
        aspect-ratio: 16 / 9;
        margin-block-start: 2rem;
        border: none;
    }

    &__bottom-wrapper {
        padding-block-end: 2rem;

        @include for-size(tablet-portrait-up) {
            display: grid;
            grid-template-columns: 50% calc(50% - 4rem);
            justify-content: space-between;
            margin-block-start: 4rem;
            padding-block-end: unset;
        }
    }

    &__speakers-wrapper {
        margin-block-start: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: unset;
        }
    }

    &__speakers-list {
        grid-template-columns: repeat(3, 1fr);
        margin-block-start: 2rem;

        @include for-size(phone-only) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__downloads-wrapper {
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-block-start: 0;
        }
    }

    &__downloads {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 2rem;
    }

    &__title,
    &__speakers-title,
    &__downloads-title {
        @extend %heading-3;
    }
}
