@import "src/styles";

.vacancy-card {
    display: flex;
    flex-direction: column;
    padding-block: 2rem;
    padding-inline: 0 2rem;
    color: var(--c-blue);

    @include for-size(laptop-up) {
        padding-block: 3.5rem;
    }

    @include is-dark-child {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-3;

        color: inherit;
    }

    &__subtitle {
        margin-block-start: .5rem;
        font-size: 1rem;
        color: var(--c-grey);
    }

    &__description {
        margin-block-start: 2rem;
    }

    &__download-button {
        margin-block-start: 3rem;
    }
}
