@import "src/styles";

.article-search-result-item {
    --c-read-more: currentColor;

    display: flex;
    flex-direction: column;
    position: relative;
    padding-block: 1rem;

    &:hover,
    &:focus-within {
        --c-read-more: var(--c-accent);
    }

    &__title {
        @extend %heading-4;

        color: currentColor;
        margin-block-start: 1.5rem;
    }

    &__meta-wrapper {
        order: -1;
    }

    &__category {
        font-size: 1rem;
        color: var(--c-primary);
    }

    &__date {
        margin-block-start: .5rem;
        font-size: 1rem;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__read-more-label {
        @extend %underscore-button;

        font-size: 1rem;
        color: var(--c-read-more);
    }
}
