@import "src/styles";

.password-input {
    display: block;

    &__label {
        margin-block-end: .5rem;
    }

    &__field-wrapper {
        position: relative;
        display: flex;
    }

    &__field {
        border-inline-end: none;
        border-radius: var(--border-radius);
        border-start-end-radius: 0;
        border-end-end-radius: 0;
    }

    &__visibility-button {
        @extend %button;

        padding-block: 1rem;
        padding-inline: 1.5rem;
        border-radius: var(--border-radius);
        border-start-start-radius: 0;
        border-end-start-radius: 0;
        background-color: var(--c-accent);
    }

    &__visibility-icon {
        @include square(1.25rem);
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
