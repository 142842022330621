@import "src/styles";

.table-cell {
    @include column-width(12.5rem);

    display: flex;
    align-items: normal;
    height: auto;
    min-height: 4rem;
    padding: 1rem;
    color: var(--c-blue);

    &:not(:last-child) {
        border-inline-end: 1px solid var(--c-line);
    }

    @include is-dark-child {
        border-color: var(--c-line-dark);
        color: var(--c-white);
    }

    &--is-bold {
        font-weight: var(--fw-bold);
    }

    &--align-left {
        justify-content: flex-start;
        text-align: start;
    }

    &--align-center {
        justify-content: center;
        text-align: center;
    }

    &--align-right {
        justify-content: flex-end;
        text-align: end;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        align-self: center;
        text-align: start;
    }
}
