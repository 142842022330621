@import "src/styles";

.annual-downloads {

    @include is-dark-parent {
        color: var(--c-white);
    }

    &__title {
        @extend %heading-2;
    }

    &__year-selector {
        margin-block-start: 2rem;
    }

    &__list {
        padding-block: 2rem;
        border: 1px solid var(--c-line);
        border-inline-end: 0;

        @include is-dark-child {
            border-color: var(--c-line-dark);
        }
    }

    &__background-illustration {
        align-items: flex-end;
    }

    &__background-illustration-picture {
        position: relative;
        z-index: -10;
        height: 50rem;
        transform: translate(0, 20%) scaleX(-100%);

        @include is-dark-child {
            background-color: transparent;
        }
    }
}
