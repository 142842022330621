@import "helpers";
@import "typography";
@import "animations";
@import "variables";

* {
    box-sizing: border-box;
}

:focus:not(:focus-visible) {
    outline: none;
}

html {
    font-size: 100%;
    font-variant-ligatures: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    font-weight: var(--fw-regular);
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--c-blue);
    background-color: var(--c-white);

    &.has-active-modal {
        overflow: hidden;
    }
}

h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: var(--fw-regular);
}

p {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.75;
}

blockquote {
    quotes: "“" "”";
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.6;

    @include for-size(tablet-portrait-up) {
        font-size: 1.75rem;
        line-height: 1.42;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#app-root {
    position: relative;
    z-index: 10;
}

#portal-root {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
}

.grecaptcha-badge {
    display: none !important;
    visibility: hidden;
}
