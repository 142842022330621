@import "src/styles";

.share {

    &__content {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        border-block-end: 1px solid var(--c-line);
        border-inline-start: 1px solid var(--c-line);
        padding-inline: 2rem;
        width: 100%;
        min-height: 7rem;
        color: var(--c-grey);
        background: var(--c-white);

        @include is-dark-child {
            border-color: var(--c-line-dark);
            color: var(--c-grey-light);
            background: var(--c-dark);
        }

        @include for-size(tablet-portrait-up) {
            gap: 1.5rem;
            width: calc(100% / 3);
            padding-inline: 1rem;
        }

        @include for-size(tablet-landscape-up) {
            gap: 2rem;
        }

        @include for-size(laptop-up) {
            gap: 2.5rem;
            padding-inline: 2rem;
        }
    }

    &__title {
        @extend %sub-text;
    }

    &__button {
        @extend %button--no-background;
        @include icon-size(1rem);

        padding: 0;
        color: currentColor;

        &--linked-in {
            margin-block-start: -.25rem;
        }

        &--link {
            @include icon-size(1.25rem);

            &:disabled {
                color: currentColor;
                background-color: transparent;

                &:hover {
                    color: currentColor;
                }
            }
        }

        &:hover,
        &:focus {
            color: var(--c-black);

            @include is-dark-child {
                color: var(--c-white);
            }
        }
    }
}
