@import "src/styles";

.contact-company {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: currentColor;

    &__title {
        @extend %heading-3;
    }

    &__address {
        font-size: 1.125rem;
        font-style: normal;
    }

    &__address-name {
        font-weight: var(--fw-bold);
    }

    &__line {
        margin-block-start: .375rem;
        text-decoration: none;
        color: inherit;
    }
}
