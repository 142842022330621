@import "src/styles";

.table-row {
    display: flex;
    align-items: normal;
    width: 100%;
    border-block-start: 1px solid var(--c-blue-dark);
    background-color: inherit;

    @include is-dark-child {
        border-color: var(--c-blue);
    }

    &:first-child {
        border-block-start: 2px solid var(--c-accent);
    }

    &:nth-child(odd) {
        background-color: rgba(var(--c-blue-rgb), .05);

        @include is-dark-child {
            background-color: rgba(var(--c-blue-rgb), .25);
        }
    }
}
