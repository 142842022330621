@import "src/styles";

.number-input {
    display: block;

    &__label {
        margin-block-end: .5rem;
    }

    &__field-wrapper {
        display: flex;
    }

    &__field {
        -moz-appearance: textfield;
        border-inline-end: none;
        border-start-start-radius: var(--border-radius);
        border-start-end-radius: 0;
        border-end-start-radius: var(--border-radius);
        border-end-end-radius: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    &__control-wrapper {
        display: flex;
        flex-direction: column;

        @include touch-screen {
            flex-direction: row-reverse;
        }
    }

    &__control-button {
        position: relative;
        z-index: 1;
        padding-block: 0;
        padding-inline: 1rem;
        height: 100%;
        border-radius: 0;
        background-color: var(--c-accent);

        &--plus {
            border-start-end-radius: var(--border-radius);

            @include touch-screen {
                border-end-end-radius: var(--border-radius);
            }
        }

        &--minus {
            border-end-end-radius: var(--border-radius);

            @include touch-screen {
                border-radius: 0;
            }
        }
    }

    &__error-label {
        margin-block-start: .5rem;
    }
}
