@import "src/styles";

.non-sort-table {

    &__header-row:nth-child(1) {
        border-block-start: unset;
        background-color: var(--c-white);

        @include is-dark-child {
            background-color: var(--c-blue-dark);
        }
    }

    &__hidden-cell {
        @include column-width(0);
    }
}
